import { useSlider } from 'hooks';
import { Dnd } from 'components/dnd';
import { simulateChange } from 'utils/helpers/simulate-change';
import { MonitorGroup } from 'utils/api/api';
import { ReactElement, useCallback, useRef } from 'react';

import './styles.scss';
import { splitArray } from 'utils/formatters/split-array';
import { orderObjects, toast } from 'utils';
import { useIntl } from 'react-intl';
import { IMonitorGroupItem } from './monitors-group';

export interface IWallProps {
	groupIds?: IMonitorGroupItem[];
	slider: ReturnType<typeof useSlider<[number, number]>>;
}

const KEY_SEPARATOR = ':';

export function createWallKey({
	monitorId,
	row,
	col,
}: Omit<MonitorGroup, 'monitorId'> & Pick<Partial<MonitorGroup>, 'monitorId'>) {
	return [monitorId, row, col].join(KEY_SEPARATOR);
}

export function parseWallKey(key: string) {
	return Object.fromEntries(
		key.split(KEY_SEPARATOR).map((v, idx) => {
			let key,
				value: string | number = v;

			switch (idx) {
				case 0:
					{
						key = 'monitorId';
					}
					break;
				case 1:
					{
						key = 'row';
						value = Number(v);
					}
					break;
				case 2: {
					key = 'col';
					value = Number(v);
				}
			}
			return [key, value];
		}),
	);
}

export function orderWall<T, K>(
	array: T[],
	colCount: number,
	rowCount: number,
	options?: Partial<{
		order: boolean;
		filter: boolean;
		map: (item: T, row: number, col: number) => K;
	}>,
) {
	const { order, filter, map } = options || {};

	let wall = splitArray(array, {
		itemsLength: colCount,
		sectionCount: rowCount,
	}).flatMap((arr, rowIdx) =>
		arr.map((item, colIdx) => {
			const row = rowIdx + 1,
				col = colIdx + 1;
			if (map) {
				return map(item, row, col);
			}
			return {
				...item,
				row,
				col,
			};
		}),
	);

	if (filter || order) {
		wall = wall.filter((item): item is NonNullable<typeof item> =>
			Boolean(item),
		);
	}

	if (order) {
		wall = orderObjects(wall);
	}

	return wall;
}

export function Wall({ groupIds = [], slider }: IWallProps) {
	const intl = useIntl();
	const inputRef = useRef<HTMLInputElement>(null);
	const [{ currentSlide }, { nextSlide, prevSlide }] = slider;

	const handleDndChange = useCallback(
		(children: ReactElement[]) => {
			if (!currentSlide) {
				return toast.error(
					intl.formatMessage({
						id: 'UnknownError',
						defaultMessage: 'Неопознанная ошибка при сохранении шаблона!',
					}),
				);
			}
			simulateChange(
				inputRef,
				orderWall(children, ...currentSlide, {
					map: (child, row, col) => {
						const { monitorId } = parseWallKey(child.key as string);
						const m = groupIds.find((m) => m.monitorId === monitorId);

						return (
							m && {
								...m,
								row,
								col,
							}
						);
					},
					filter: true,
					order: true,
				}),
			);
		},
		[currentSlide, groupIds, intl],
	);

	return (
		<div className="monitor-wall">
			<input ref={inputRef} type="hidden" name="groupIds" />
			<button
				type="button"
				className="monitor-wall__arrow prev"
				onClick={prevSlide}
			>
				{'<'}
			</button>
			{currentSlide && (
				<Dnd
					className="monitor-wall__dnd"
					style={{
						// @ts-expect-error: Custom CSS property
						'--col-length': currentSlide[0],
						'--row-length': currentSlide[1],
					}}
					rearrange
					onRearrange={handleDndChange}
				>
					{Array.from({ length: currentSlide[1] })
						.flatMap((empty, rowIdx) =>
							Array.from({ length: currentSlide[0] }).map((empty, colIdx) => {
								return [rowIdx, colIdx].map((idx) => idx + 1);
							}),
						)
						.map(([row, col], idx) => {
							const m = groupIds.find((m, mIdx) => {
								if (!m.monitorId) {
									return false;
								}
								if (m.col || m.row) {
									return m.col === col && m.row === row;
								}
								return mIdx === idx;
							});

							return (
								<div
									key={createWallKey({ monitorId: m?.monitorId, row, col })}
									className="monitor-wall__item"
									draggable={Boolean(m)}
								>
									<span className="monitor-wall__item-label">
										{m ? m.order : '-'}
									</span>
								</div>
							);
						})}
				</Dnd>
			)}
			<button
				type="button"
				className="monitor-wall__arrow next"
				onClick={nextSlide}
			>
				{'>'}
			</button>
		</div>
	);
}
