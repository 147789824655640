import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import routes from 'modules/routing-module/routes';
import { Confirmation } from 'components/common';
import { playlistsStore } from 'stores/playlists';

const PlaylistDelete: FC = () => {
	const intl = useIntl();

	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const playlist = playlistsStore.list.find((p) => p.id === id);

	const message = intl.formatMessage(
		{
			id: 'Deleting Confirm',
		},
		{ it: playlist ? playlist.name : id },
	);

	const handleConfirm = useCallback(() => {
		if (id) {
			playlistsStore.deletePlaylist(id);

			navigate(routes.PlaylistsList.path, { replace: true });
		}
	}, [id, navigate]);

	const handleCancel = useCallback(() => {
		navigate(routes.PlaylistsList.path, { replace: true });
	}, [navigate]);

	return (
		<Confirmation
			messageId={message}
			onConfirm={handleConfirm}
			onCancel={handleCancel}
		/>
	);
};

export default observer(PlaylistDelete);
