import {
	DragEvent,
	HTMLAttributes,
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';

import './styles.scss';
import { toast } from 'utils';
import { useIntl } from 'react-intl';

export interface IDndProps
	extends Omit<
		HTMLAttributes<HTMLDivElement>,
		'children' | 'onChange' | 'onDrop'
	> {
	children: ReactElement[];
	rearrange?: boolean;
	onRearrange?: (children: ReactElement[]) => void;
	onDrop?: (props: { droppedKey: string; draggedKey: string }) => void;
}

export const CLASSNAMES = {
	DRAGGABLE: 'dnd__draggable',
	DROPPABLE: 'dnd__droppable',
	OVER: 'over',
	GRABBING: 'grabbing',
};

export function handleDndDrop(e: DragEvent) {
	const { key: draggedKey } = JSON.parse(
		e.dataTransfer?.getData('text/plain'),
	) as {
		key: ReactElement['key'];
	};

	return draggedKey;
}

export function Dnd({
	children,
	className,
	onRearrange,
	onDrop,
	rearrange,
	...restProps
}: IDndProps) {
	const intl = useIntl();
	const dndRef = useRef<HTMLDivElement>(null);
	const [rearrangeData, setRearrangeData] = useState<ReactElement[] | null>(
		null,
	);

	const hideOverEffect = useCallback((elem: HTMLDivElement) => {
		elem.classList.remove(CLASSNAMES.OVER);
	}, []);

	const handleDragStart = (
		e: DragEvent<HTMLDivElement>,
		draggedChild: ReactElement,
	) => {
		const key = draggedChild.key;
		if (!key) {
			throw new Error(`Dragged element needs "key" prop: ${key}`);
		}
		e.dataTransfer.setData('text/plain', JSON.stringify({ key }));
		e.dataTransfer.effectAllowed = 'move';
		e.currentTarget.classList.add(CLASSNAMES.GRABBING);
	};

	const handleDragEnd = (e: DragEvent<HTMLDivElement>) => {
		e.currentTarget.classList.remove(CLASSNAMES.GRABBING);
	};

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.currentTarget.classList.add(CLASSNAMES.OVER);
	};

	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		hideOverEffect(e.currentTarget);
	};

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>, droppedChild: ReactElement) => {
			e.preventDefault();
			const droppedKey = droppedChild.key;
			if (!droppedKey) {
				throw new Error(`Dropped element needs "key" prop: ${droppedKey}`);
			}

			try {
				const draggedKey = handleDndDrop(e);
				if (!draggedKey) return;

				if (rearrange) {
					const rearrangeData = [...children];
					const droppedIdx = children.findIndex((c) => c.key === droppedKey);
					const draggedIdx = children.findIndex((c) => c.key === draggedKey);

					rearrangeData[droppedIdx] = children[draggedIdx];
					rearrangeData[draggedIdx] = children[droppedIdx];

					setRearrangeData(rearrangeData);
				}
				hideOverEffect(e.currentTarget);
				if (onDrop) {
					onDrop({ droppedKey, draggedKey });
				}
				e.stopPropagation();
			} catch (e) {
				const errorMessage = intl.formatMessage({
					id: 'DndError',
					defaultMessage:
						'Непредвиденная ошибка при перетаскивания елемента страницы.',
				});
				toast.error(errorMessage);
				console.error(errorMessage, e);
			}
		},
		[setRearrangeData, children, hideOverEffect, intl],
	);

	useEffect(() => {
		if (onRearrange && rearrangeData) {
			onRearrange(rearrangeData);
			setRearrangeData(null);
		}
	}, [rearrangeData, onRearrange]);

	return (
		<div {...restProps} ref={dndRef} className={cx('dnd', className)}>
			{children.map((child) => {
				const { draggable = true } = child.props;
				return (
					<div
						key={child.key}
						className={cx(CLASSNAMES.DROPPABLE)}
						onDragOver={handleDragOver}
						onDragLeave={handleDragLeave}
						onDrop={(el) => handleDrop(el, child)}
					>
						<div
							className={cx(CLASSNAMES.DRAGGABLE)}
							draggable={draggable}
							onDragStart={(el) => handleDragStart(el, child)}
							onDragEnd={handleDragEnd}
						>
							{child}
						</div>
					</div>
				);
			})}
		</div>
	);
}
