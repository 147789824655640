import {
	action,
	computed,
	makeObservable,
	observable,
	runInAction,
} from 'mobx';
import cloneDeep from 'clone-deep';

import { filterObject, handleError, swaggerApi } from 'utils';

import {
	IEditorProject,
	IEditorTrack,
	IHistoryItem,
	IVideoEditorStore,
} from './types';
import { createRef } from 'react';

import { normalizeLayer, normalizeProject } from './engine';

import { appStore } from 'stores/app';
import { explorerStore } from 'stores/media';
import { playlistsStore } from 'stores/playlists';
import { TPromiseExecutor } from '_types/common';

import {
	EditorLayerResponse,
	FileResponse,
	FileType,
	RenderingStatus,
} from 'utils/api/api';
import { toast, toastDownload } from 'utils/toast';
import { monitorsStore } from 'stores/monitors/monitor-list';

export const ONE_SECOND_MS = 1000;
const ZOOM_FACTOR_PX = 0.5;
const ZOOM_FACTOR_SEC = 2;
export const DEFAULT_SEC_TO_PX = 6.9;
export const DEFAULT_SCALE_UNIT_SECONDS = 5;
const MAX_SCALE_UNIT_SECONDS = 20;
const MIN_SCALE_UNIT_SECONDS = 1;
const EDITOR_STORAGE = 'editorStore';
export const DEFAULT_IMAGE_DURATION_SECONDS = 5;
const DEFAULT_MEDIA_VOLUME = 1;
const DEFAULT_RENDERING_STATUS = RenderingStatus.Initial;

export const DRAFT_PROJECT_ID = 'draft';
export const DEFAULT_FPS = 24;
export const draftProject: IEditorProject = {
	audioLayers: [],
	createdAt: '',
	fps: DEFAULT_FPS,
	height: 1080,
	id: DRAFT_PROJECT_ID,
	name: 'Черновик',
	renderingError: '',
	renderedFile: {} as IEditorProject['renderedFile'],
	renderingPercent: 0,
	renderingStatus: DEFAULT_RENDERING_STATUS,
	keepSourceAudio: true,
	videoLayers: [],
	width: 1920,
	totalDuration: 0,
	updatedAt: '',
	userId: '',
};
export const trackRequestKeys: Array<keyof EditorLayerResponse> = [
	'cutFrom',
	'cutTo',
	'duration',
	'file',
	'index',
	'mixVolume',
	'start',
];

class VideoEditorStore implements IVideoEditorStore {
	@observable _currentFrame: IVideoEditorStore['_currentFrame'] = 0;
	@observable history: IVideoEditorStore['history'] = {
		lastItemId: '',
		items: [],
	};
	@observable isPlayed: IVideoEditorStore['isPlayed'] = false;
	@observable imageDurationSeconds: IVideoEditorStore['imageDurationSeconds'] =
		DEFAULT_IMAGE_DURATION_SECONDS;
	@observable
	previewCanvasRef: IVideoEditorStore['previewCanvasRef'] =
		createRef<HTMLCanvasElement>();
	@observable project: IVideoEditorStore['project'] = null;
	@observable
	renderingStatusByProjectId: IVideoEditorStore['renderingStatusByProjectId'] =
		{};
	@observable selectedTrackIds: IVideoEditorStore['selectedTrackIds'] = [];
	@observable
	scaleUnitSeconds: IVideoEditorStore['scaleUnitSeconds'] =
		DEFAULT_SCALE_UNIT_SECONDS;
	@observable
	secondsToPixelFactor: IVideoEditorStore['secondsToPixelFactor'] =
		DEFAULT_SEC_TO_PX;
	@observable
	tracksDraggableShift: IVideoEditorStore['tracksDraggableShift'] = 0;
	@observable
	trackDurationStretch: IVideoEditorStore['trackDurationStretch'] = null;
	@observable view: IVideoEditorStore['view'] = 'window';
	@observable waitAudioId: IVideoEditorStore['waitAudioId'] = null;
	@observable waitLayerId: IVideoEditorStore['waitLayerId'] = null;
	_timelineAnimationFrame: IVideoEditorStore['_timelineAnimationFrame'] = 0;
	_contextHistoryItem: IVideoEditorStore['_contextHistoryItem'] = null;
	_compiledStatusByProjectId: IVideoEditorStore['_compiledStatusByProjectId'] =
		{};
	mediaIdToAudio: IVideoEditorStore['mediaIdToAudio'] = {};
	mediaIdToImage: IVideoEditorStore['mediaIdToImage'] = {};
	mediaIdToVideo: IVideoEditorStore['mediaIdToVideo'] = {};
	playedAudioId: IVideoEditorStore['playedAudioId'] = null;
	playedLayerId: IVideoEditorStore['playedLayerId'] = null;

	constructor() {
		makeObservable(this);
	}

	@computed get audioTracks() {
		return this.project ? this.project.audioLayers : [];
	}

	@computed get currentSeconds() {
		return this._currentFrame / ONE_SECOND_MS;
	}

	@computed get layerTracks() {
		return this.project ? this.project.videoLayers : [];
	}

	@computed get isReadOnly() {
		return this.renderingStatus === 'pending';
	}

	@computed get previewAudio() {
		if (!this.project) return null;

		const previewAudio = this.project.audioLayers.find(
			(l): l is IEditorTrack => {
				const lEnd = l.start + l.duration;
				return this.currentSeconds >= l.start && this.currentSeconds <= lEnd;
			},
		);

		return previewAudio || null;
	}

	@computed get previewLayer() {
		if (!this.project) return null;

		const previewLayer = this.project.videoLayers.find(
			(l): l is IEditorTrack => {
				const lEnd = l.start + l.duration;
				return this.currentSeconds >= l.start && this.currentSeconds <= lEnd;
			},
		);

		return previewLayer || null;
	}

	@computed get notSelectedTracks() {
		const notSelectedTracks: IVideoEditorStore['notSelectedTracks']['audio'] = {
			prevTracks: [],
			nextTracks: [],
			prevTrack: null,
			nextTrack: null,
		};
		if (!this.project) {
			return [FileType.Audio, FileType.Video].reduce<
				IVideoEditorStore['notSelectedTracks']
			>(
				(acc, type) => ({
					...acc,
					[type]: notSelectedTracks,
				}),
				{} as IVideoEditorStore['notSelectedTracks'],
			);
		}

		return [FileType.Audio, FileType.Video].reduce<
			IVideoEditorStore['notSelectedTracks']
		>(
			(acc, type) => {
				const tracks =
					type === FileType.Audio
						? this.project?.audioLayers
						: this.project?.videoLayers;

				if (tracks) {
					return {
						...acc,
						[type]: tracks
							.filter((track) => !this.selectedTrackIds.includes(track.id))
							.reduce(
								({ prevTracks, nextTracks, prevTrack, nextTrack }, track) => {
									if (
										this.selectedTracks.every(
											(t) => track.index !== t.index && track.index > t.index,
										)
									) {
										if (nextTracks.every((t) => track.index < t.index)) {
											nextTrack = track;
										}
										nextTracks.push(track);
									} else {
										if (prevTracks.every((t) => track.index > t.index)) {
											prevTrack = track;
										}
										prevTracks.push(track);
									}
									return {
										prevTracks,
										nextTracks,
										prevTrack,
										nextTrack,
									};
								},
								cloneDeep(
									notSelectedTracks,
								) as IVideoEditorStore['notSelectedTracks']['audio'],
							),
					};
				}
				return acc;
			},
			{} as IVideoEditorStore['notSelectedTracks'],
		);
	}

	@computed get renderingStatus() {
		return this.project
			? this.renderingStatusByProjectId[this.project.id]
			: DEFAULT_RENDERING_STATUS;
	}

	@computed get selectedTracks() {
		const trackFilter = (t: IEditorTrack) =>
			this.selectedTrackIds.includes(t.id);

		return this.project
			? [
					this.project.audioLayers.filter(trackFilter),
					this.project.videoLayers.filter(trackFilter),
				].flat()
			: [];
	}

	@computed get trackDurationStretchShiftX() {
		return this.trackDurationStretch ? this.trackDurationStretch.shiftX : 0;
	}

	@computed get timelinePointerShift() {
		return this.currentSeconds * this.secondsToPixelFactor;
	}

	@computed get totalDuration() {
		return this.project ? this.project.totalDuration : 0;
	}

	@computed get tracksDragging() {
		return this.tracksDraggableShift !== 0;
	}

	@action
	moveTimelinePointer: IVideoEditorStore['moveTimelinePointer'] = ({
		shiftX,
		x,
	}) => {
		if (!Number.isNaN(shiftX) && typeof shiftX === 'number') {
			const frameShift = (shiftX / this.secondsToPixelFactor) * ONE_SECOND_MS;
			const currentFrame = this._currentFrame + frameShift;
			if (currentFrame >= 0) {
				this._currentFrame = currentFrame;
				this._drawPreview();
				this._soundPreview();
				return true;
			}
		} else if (!Number.isNaN(x) && typeof x === 'number') {
			const currentFrame = (x / this.secondsToPixelFactor) * ONE_SECOND_MS;
			if (currentFrame >= 0) {
				this._currentFrame = currentFrame;
				if (this.isPlayed) {
					// replay with current frame
					this.play();
				} else {
					this._drawPreview();
					this._soundPreview();
				}
				return true;
			}
		}
		return false;
	};

	@action cutTrack: IVideoEditorStore['cutTrack'] = async (track) => {
		if (!this.project || !track) {
			/* TODO@nikshirobokov: Add error info messages about. For other methods also. */
			return [];
		}
		const cutSeconds = Math.round(this.currentSeconds - track.start);
		if (cutSeconds <= 0) {
			return [];
		}
		const cutTo = track.cutFrom + cutSeconds;
		const isAudio = track.type === FileType.Audio;

		try {
			await this.updateTrack(track.id, track.type, {
				...track,
				cutTo,
				duration: cutTo - track.cutFrom,
				index: track.index,
			});

			const index = track.index + 1;

			const tracks = await this.postTrack({
				...track,
				start: track.start + cutSeconds,
				cutFrom: cutTo,
				duration: track.cutTo - cutTo,
				index,
			});

			await this.initProject({
				project: {
					...this.project,
					...(isAudio ? { audioLayers: tracks } : { videoLayers: tracks }),
				},
			});

			return (isAudio ? this.project.audioLayers : this.project.videoLayers)
				.filter((t) => [track.index, index].includes(t.index))
				.sort((t1, t2) => (t1.index > t2.index ? 1 : -1));
		} catch (e) {
			toast.error(handleError(e));
			return [];
		}
	};

	@action moveTrack: IVideoEditorStore['moveTrack'] = (shiftX) => {
		if (!this.project) return this.tracksDraggableShift;

		if (this.selectedTracks.length) {
			const isAudio = this.selectedTracks.some(
				(t) => t.type === FileType.Audio,
			);
			const tracksDraggableSeconds = shiftX / this.secondsToPixelFactor;
			const { nextTrack, prevTrack } = isAudio
				? this.notSelectedTracks.audio
				: this.notSelectedTracks.video;
			const firstSelectedTrack = this.selectedTracks.sort((t1, t2) =>
				t1.start > t2.start ? 1 : -1,
			)[0];
			const selectedDuration = this.selectedTracks.reduce(
				(sum, t) => sum + t.duration,
				0,
			);
			const selectedCenterSeconds =
				tracksDraggableSeconds +
				firstSelectedTrack.start +
				selectedDuration / 2;
			let tracks;

			if (
				nextTrack &&
				selectedCenterSeconds > nextTrack.start + nextTrack.duration / 2
			) {
				tracks = (
					isAudio ? this.project.audioLayers : this.project.videoLayers
				).map((track) => {
					if (track.id === nextTrack.id) {
						return {
							...track,
							index: track.index - this.selectedTrackIds.length,
							start: track.start - selectedDuration,
						};
					} else if (this.selectedTrackIds.includes(track.id)) {
						return {
							...track,
							index: track.index + 1,
							start: track.start + nextTrack.duration,
						};
					}
					return track;
				});

				shiftX -= nextTrack.duration * this.secondsToPixelFactor;
			} else if (
				prevTrack &&
				selectedCenterSeconds < prevTrack.start + prevTrack.duration / 2
			) {
				tracks = (
					isAudio ? this.project.audioLayers : this.project.videoLayers
				).map((track) => {
					if (track.id === prevTrack.id) {
						return {
							...track,
							index: track.index + this.selectedTrackIds.length,
							start: track.start + selectedDuration,
						};
					} else if (this.selectedTrackIds.includes(track.id)) {
						return {
							...track,
							index: track.index - 1,
							start: track.start - prevTrack.duration,
						};
					}
					return track;
				});

				shiftX += prevTrack.duration * this.secondsToPixelFactor;
			}

			if (tracks) {
				this._updateHistory('moveTrack', {
					isAudio,
					startTracks: (isAudio
						? this.project.audioLayers
						: this.project.videoLayers
					).concat(),
					finalTracks: tracks,
					movedTracks: this.selectedTracks.concat(),
				});

				this.initProject({
					project: {
						...this.project,
						...(isAudio ? { audioLayers: tracks } : { videoLayers: tracks }),
					},
				});
			}
		}
		this.tracksDraggableShift = shiftX;

		return this.tracksDraggableShift;
	};

	@action addMedia: IVideoEditorStore['addMedia'] = async (
		name,
		mediaItems,
	) => {
		if (!this.project || !mediaItems.length) return;

		if (this.project.id === DRAFT_PROJECT_ID) {
			if (!name) return;

			const project = await this.createProject(
				name,
				filterObject(this.project, {
					includedKeys: ['width', 'height', 'fps', 'keepSourceAudio'],
				}),
			);
			if (!project) return;
			this.project = project;
		}

		const isAudio = mediaItems.some((m) => m.type === FileType.Audio);
		const existingTracks = (
			isAudio ? this.project.audioLayers : this.project.videoLayers
		).concat();
		const totalStart = existingTracks.reduce((sum, t) => sum + t.duration, 0);

		const postTracks = async (
			start: number,
			idx: number,
		): Promise<IEditorTrack[]> => {
			const mediaItem = mediaItems[idx];
			const trackDuration =
				mediaItem.type === FileType.Image
					? this.imageDurationSeconds
					: mediaItem.duration;

			const tracks = await this.postTrack({
				type: mediaItem.type,
				mediaId: mediaItem.id,
				start,
				duration: trackDuration,
				cutFrom: 0,
				cutTo: trackDuration,
				index: existingTracks.length + (idx + 1),
			});

			return mediaItems[idx + 1]
				? postTracks(start + trackDuration, idx + 1)
				: tracks;
		};

		const addMediaCallback = async () => {
			if (!this.project || !mediaItems.length) return;
			const tracks = await postTracks(totalStart, 0);
			const lastLayer = (
				isAudio ? this.project.videoLayers : this.project.audioLayers
			)
				.concat(tracks)
				.reduce<IEditorTrack>(
					(lastLayer, l) =>
						l.start + l.duration > lastLayer.start + lastLayer.duration
							? l
							: lastLayer,
					tracks[tracks.length - 1],
				);

			this._updateHistory('addMedia', {
				isAudio,
				addedTracks: tracks.filter((t) =>
					existingTracks.every((exTrack) => exTrack.id !== t.id),
				),
				mediaItems,
				totalStart,
			});

			await this.initProject({
				project: {
					...this.project,
					...(isAudio ? { audioLayers: tracks } : { videoLayers: tracks }),
					totalDuration: lastLayer.start + lastLayer.duration,
				},
			});
		};

		try {
			await this._withPromiseWrapper(addMediaCallback)();
		} catch (e) {
			toast.error(handleError(e));
		}
	};

	@action.bound cut: IVideoEditorStore['cut'] = async () => {
		if (!this.project) return false;
		const { previewAudio, previewLayer } = this;

		const [resultAudioTracks, resultLayers] = await Promise.all(
			[previewAudio, previewLayer].map((track) =>
				track ? this.cutTrack(track) : [],
			),
		);

		this._updateHistory('cut', {
			cutFrame: this._currentFrame,
			donor: {
				audioTrack: previewAudio && cloneDeep(previewAudio),
				layerTrack: previewLayer && cloneDeep(previewLayer),
			},
			result: {
				audioTracks: resultAudioTracks,
				videoLayers: resultLayers,
			},
		});

		this.unSelectTracks();

		return true;
	};

	@action.bound delete: IVideoEditorStore['delete'] = async () => {
		const deletedTracks = this.selectedTracks.concat();
		if (!deletedTracks.length) return false;

		const deleteCallback = async () => {
			if (!this.project) return false;

			const deleteTracks = async (idx: number) => {
				const track = deletedTracks[idx];
				if (!this.project) {
					throw new Error(
						`Invalid value of the "project" property: ${this.project}, by "POST" request with track: ${track.id}`,
					);
				}

				await swaggerApi.api.editorLayerDelete(this.project.id, track.id);

				if (deletedTracks[idx + 1]) {
					await deleteTracks(idx + 1);
				}
			};
			await deleteTracks(0);

			this._updateHistory('delete', {
				deletedTracks,
			});

			await this.initProject({
				projectId: this.project.id,
			});

			return true;
		};

		try {
			return this._withPromiseWrapper<boolean>(deleteCallback)();
		} catch (e) {
			toast.error(handleError(e));
			return false;
		} finally {
			this.unSelectTracks();
		}
	};

	@action
	undo: IVideoEditorStore['undo'] = async () => {
		if (!this.project) return;
		const lastIdx = this.history.items.findIndex(
			(item) => this.history.lastItemId === item.id,
		);
		const lastHistoryItem = this.history.items.find(
			(item) => item.id === this.history.lastItemId,
		);
		if (!lastHistoryItem) return;
		try {
			this._contextHistoryItem = lastHistoryItem;

			switch (lastHistoryItem.action) {
				case 'addMedia':
					{
						const { addedTracks } = lastHistoryItem.body as {
							addedTracks: IEditorTrack[];
						};
						this.unSelectTracks();
						addedTracks.forEach(this.selectTrack);
						await this.delete();
					}
					break;

				case 'cut':
					{
						const { donor, result } = lastHistoryItem.body as {
							cutFrame: IVideoEditorStore['_currentFrame'];
							donor: {
								audioTrack: IVideoEditorStore['previewAudio'];
								layerTrack: IVideoEditorStore['previewLayer'];
							};
							result: {
								audioTracks: IEditorTrack[];
								videoLayers: IEditorTrack[];
							};
						};
						this.unSelectTracks();
						Object.values(result).flat().forEach(this.selectTrack);
						await this.delete();
						const { audioTrack, layerTrack } = donor;

						const projectData: Partial<IEditorProject> = {};
						if (audioTrack) {
							projectData.audioLayers = await this.postTrack(audioTrack, []);
						}
						if (layerTrack) {
							projectData.videoLayers = await this.postTrack(layerTrack, []);
						}

						await this.initProject({
							project: {
								...this.project,
								...projectData,
							},
						});
					}
					break;

				case 'delete':
					{
						const { deletedTracks } = lastHistoryItem.body as {
							deletedTracks: IEditorTrack[];
						};

						const unDeleteTracks = async (
							idx: number,
							deletedTracks: IEditorTrack[],
						): Promise<IEditorTrack[]> => {
							const tracks = await this.postTrack(deletedTracks[idx], []);

							return deletedTracks[idx + 1]
								? unDeleteTracks(idx + 1, deletedTracks)
								: tracks;
						};

						const [audioLayers, videoLayers] = await Promise.all(
							deletedTracks
								.reduce(
									(acc, track) => {
										if (track.type === FileType.Audio) {
											acc[0] = acc[0].concat(track);
										} else {
											acc[1] = acc[1].concat(track);
										}
										return acc;
									},
									[[], []] as [IEditorTrack[], IEditorTrack[]],
								)
								.map((tracks) =>
									tracks.length ? unDeleteTracks(0, tracks) : [],
								),
						);

						await this.initProject({
							project: {
								...this.project,
								audioLayers: audioLayers,
								videoLayers: videoLayers,
							},
						});
					}
					break;

				case 'moveTrack':
					{
						const { isAudio, startTracks, movedTracks } =
							lastHistoryItem.body as {
								isAudio: boolean;
								startTracks: IEditorTrack[];
								movedTracks: IEditorTrack[];
							};
						this.unSelectTracks();
						movedTracks.forEach(this.selectTrack);

						await this.initProject({
							project: {
								...this.project,
								...(isAudio
									? { audioLayers: startTracks }
									: { videoLayers: startTracks }),
							},
						});

						await this.postTrackDragging();
					}
					break;
			}

			const editorLocaleStore = localStorage.getItem(EDITOR_STORAGE);
			if (!editorLocaleStore) {
				throw new Error(`Editor store is required: ${editorLocaleStore}`);
			}
			const parsedEditorLocaleStore = JSON.parse(editorLocaleStore);
			const newLastHistoryItem = this.history.items[lastIdx - 1];

			this.history = {
				...this.history,
				lastItemId: newLastHistoryItem ? newLastHistoryItem.id : '',
			};

			localStorage.setItem(
				EDITOR_STORAGE,
				JSON.stringify({
					...parsedEditorLocaleStore,
					historyByProjectId: {
						...parsedEditorLocaleStore.historyByProjectId,
						[this.project.id]: this.history,
					},
				}),
			);
		} catch (e) {
			toast.error(handleError(e));
		} finally {
			this._contextHistoryItem = null;
		}
	};

	@action
	redo: IVideoEditorStore['redo'] = async () => {
		if (!this.project) return;
		const lastIdx = this.history.items.findIndex(
			(item) => this.history.lastItemId === item.id,
		);
		const nextHistoryItem = this.history.items[lastIdx + 1];
		if (!nextHistoryItem) return;
		try {
			this._contextHistoryItem = nextHistoryItem;

			switch (nextHistoryItem.action) {
				case 'addMedia':
					{
						const { addedTracks, isAudio, mediaItems } =
							nextHistoryItem.body as {
								addedTracks: IEditorTrack[];
								mediaItems: FileResponse[];
								isAudio: boolean;
							};

						const postTracks = async (idx: number): Promise<IEditorTrack[]> => {
							const addedTrack = addedTracks[idx];
							const mediaItem = mediaItems.find(
								(m) => m.id === addedTrack.mediaId,
							);
							if (!mediaItem) {
								throw new Error(`The "mediaItem" is required: ${mediaItem}`);
							}
							const tracks = await this.postTrack(
								{
									...addedTrack,
									type: mediaItem.type,
								},
								[],
							);

							return addedTracks[idx + 1] ? postTracks(idx + 1) : tracks;
						};

						const tracks = await postTracks(0);

						await this.initProject({
							project: {
								...this.project,
								...(isAudio
									? { audioLayers: tracks }
									: { videoLayers: tracks }),
							},
						});
					}
					break;

				case 'cut':
					{
						const { cutFrame, donor, result } = nextHistoryItem.body as {
							cutFrame: IVideoEditorStore['_currentFrame'];
							donor: {
								audioTrack: IVideoEditorStore['previewAudio'];
								layerTrack: IVideoEditorStore['previewLayer'];
							};
							result: {
								audioTracks: IEditorTrack[];
								videoLayers: IEditorTrack[];
							};
						};
						this._currentFrame = cutFrame;
						this.unSelectTracks();
						Object.values(donor)
							.filter((track): track is IEditorTrack => Boolean(track))
							.forEach(this.selectTrack);
						await this.delete();

						const postTracks = async (
							resultTracks: IEditorTrack[],
							idx: number,
						): Promise<IEditorTrack[]> => {
							const tracks = await this.postTrack(resultTracks[idx]);

							return resultTracks[idx + 1]
								? postTracks(resultTracks, idx + 1)
								: tracks;
						};

						const [audioLayers, videoLayers] = await Promise.all(
							[result.audioTracks, result.videoLayers].map((tracks) =>
								tracks.length ? postTracks(tracks, 0) : null,
							),
						);
						const projectData: Partial<IEditorProject> = {};

						if (audioLayers) {
							projectData.audioLayers = audioLayers;
						}
						if (videoLayers) {
							projectData.videoLayers = videoLayers;
						}

						await this.initProject({
							project: {
								...this.project,
								...projectData,
							},
						});
					}
					break;

				case 'delete':
					{
						const { deletedTracks } = nextHistoryItem.body as {
							deletedTracks: IEditorTrack[];
						};
						this.unSelectTracks();
						deletedTracks.forEach(this.selectTrack);
						await this.delete();
					}
					break;

				case 'moveTrack':
					{
						const { isAudio, finalTracks, movedTracks } =
							nextHistoryItem.body as {
								isAudio: boolean;
								finalTracks: IEditorTrack[];
								movedTracks: IEditorTrack[];
							};
						this.unSelectTracks();
						movedTracks.forEach(this.selectTrack);

						await this.initProject({
							project: {
								...this.project,
								...(isAudio
									? { audioLayers: finalTracks }
									: { videoLayers: finalTracks }),
							},
						});

						await this.postTrackDragging();
					}
					break;
			}

			const editorLocaleStore = localStorage.getItem(EDITOR_STORAGE);
			if (!editorLocaleStore) {
				throw new Error(`Editor store is required: ${editorLocaleStore}`);
			}
			const parsedEditorLocaleStore = JSON.parse(editorLocaleStore);

			this.history = {
				...this.history,
				lastItemId: nextHistoryItem.id,
			};

			localStorage.setItem(
				EDITOR_STORAGE,
				JSON.stringify({
					...parsedEditorLocaleStore,
					historyByProjectId: {
						...parsedEditorLocaleStore.historyByProjectId,
						[this.project.id]: this.history,
					},
				}),
			);
		} catch (e) {
			toast.error(handleError(e));
		} finally {
			this._contextHistoryItem = null;
		}
	};

	@action
	postTrackDragging: IVideoEditorStore['postTrackDragging'] = async () => {
		if (!this.project) return false;
		const isAudio = this.selectedTracks.some((t) => t.type === FileType.Audio);
		const sortedProjectTracks = (
			isAudio ? this.project.audioLayers : this.project.videoLayers
		).sort((t1, t2) => (t1.start > t2.start ? 1 : -1));

		const sortedSelectedTracks = sortedProjectTracks.filter((t) =>
			this.selectedTrackIds.includes(t.id),
		);

		const moveSelectedTracks = async (idx: number) => {
			const track = sortedSelectedTracks[idx];
			if (!this.project) {
				throw new Error(
					`Invalid value of the "project" property: ${this.project}, by "POST" request with track: ${track.id}`,
				);
			}

			await swaggerApi.api.editorLayerMove(
				this.project.id,
				track.id,
				track.index,
			);

			if (sortedSelectedTracks[idx + 1]) {
				await moveSelectedTracks(idx + 1);
			}
		};

		const postTrackDraggingCallback = async () => {
			if (!this.project) return false;
			if (
				!sortedSelectedTracks.length ||
				sortedProjectTracks.length === sortedSelectedTracks.length
			) {
				return false;
			}
			await moveSelectedTracks(0);

			await this.initProject({
				project: {
					...this.project,
					...(isAudio
						? { audioLayers: sortedProjectTracks }
						: { videoLayers: sortedProjectTracks }),
				},
			});

			return true;
		};

		try {
			return this._withPromiseWrapper<boolean>(postTrackDraggingCallback)();
		} catch (e) {
			toast.error(handleError(e));
			return false;
		} finally {
			this.unSelectTracks();
			this.tracksDraggableShift = 0;
		}
	};

	@action
	postTrackDurationStretch: IVideoEditorStore['postTrackDurationStretch'] =
		async () => {
			if (!(this.project && this.trackDurationStretch)) {
				return false;
			}
			const { track, shiftX } = this.trackDurationStretch;
			const shiftSeconds = Math.round(shiftX / this.secondsToPixelFactor);
			const duration = track.duration + shiftSeconds;

			if (duration === track.duration) {
				return false;
			}

			const isAudio = track.type === FileType.Audio;
			const tracks = isAudio
				? this.project.audioLayers
				: this.project.videoLayers;

			const updatedTracks = await Promise.all(
				tracks.map(async (t) => {
					let updated: Partial<IEditorTrack> = {};
					if (t.index === track.index) {
						updated = {
							cutFrom: track.cutFrom,
							cutTo: track.cutFrom + duration,
							duration,
						};
					} else if (t.index > track.index) {
						updated = {
							start: t.start + shiftSeconds,
						};
					}

					if (updated) {
						await this.updateTrack(t.id, t.type, updated);

						return {
							...t,
							...updated,
						};
					}

					return t;
				}),
			);

			await this.initProject({
				project: {
					...this.project,
					...(isAudio
						? { audioLayers: updatedTracks }
						: { videoLayers: updatedTracks }),
				},
			});

			this.trackDurationStretch = null;

			return true;
		};

	@action selectTrack: IVideoEditorStore['selectTrack'] = (track) => {
		this.selectedTrackIds = this.selectedTrackIds
			.filter((tId) => tId !== track.id)
			.concat(track.id);
	};

	@action unSelectTracks: IVideoEditorStore['unSelectTracks'] = (
		trackIds,
		donor,
	) => {
		if (donor) {
			this.selectedTrackIds = this.selectedTracks
				.filter(
					(t) => !Object.entries(donor).some(([k, value]) => t[k] === value),
				)
				.map((t) => t.id);
		} else if (trackIds) {
			this.selectedTrackIds = this.selectedTrackIds.filter(
				(tId) => !trackIds.includes(tId),
			);
		} else {
			this.selectedTrackIds = [];
		}
	};

	@action
	createProject: IVideoEditorStore['createProject'] = async (
		name,
		rawProject = {
			width: 1920,
			height: 1080,
			keepSourceAudio: true,
			fps: 24,
		},
	) => {
		const createProjectCallback = async () => {
			const { data: editorData } = await swaggerApi.api.editorCreate({
				...rawProject,
				name,
			});

			return await this.initProject({ project: editorData.data });
		};

		try {
			const project = await this._withPromiseWrapper<
				IVideoEditorStore['project']
			>(createProjectCallback)();

			if (project?.name) {
				toast.success(
					appStore.intl.formatMessage(
						{ id: 'Project Created' },
						{ projectName: project.name },
					),
				);
			}

			return project;
		} catch (e) {
			toast.error(handleError(e));
			return null;
		}
	};

	@action renameProject: IVideoEditorStore['renameProject'] = async (name) => {
		if (!this.project) {
			throw new Error(`Project is required ${this.project}`);
		}

		try {
			appStore.isLoading = true;

			await swaggerApi.api.editorUpdate(this.project.id, {
				name,
			});

			this.project.name = name;
		} catch (e) {
			toast.error(handleError(e));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action
	deleteProject: IVideoEditorStore['deleteProject'] = async (projectId) => {
		const deleteProjectCallback = async () => {
			return swaggerApi.api.editorDelete(projectId);
		};

		try {
			await this._withPromiseWrapper(deleteProjectCallback)();
		} catch (e) {
			toast.error(handleError(e));
		}
	};

	@action duplicateProject: IVideoEditorStore['duplicateProject'] = async (
		name,
		projectId,
	) => {
		if (projectId === DRAFT_PROJECT_ID) return;

		const donorProject: IVideoEditorStore['project'] =
			this.project && this.project.id === projectId
				? this.project
				: await this.initProject({ projectId });
		if (!donorProject) return;

		const sorter = (t1: IEditorTrack, t2: IEditorTrack) =>
			t1.id > t2.id ? 1 : -1;
		const sortedAudioTracks = donorProject.audioLayers.sort(sorter);
		const sortedLayerTracks = donorProject.videoLayers.sort(sorter);

		if (!name) return;

		await this.createProject(
			name,
			filterObject(donorProject, {
				includedKeys: ['width', 'height', 'fps', 'keepSourceAudio'],
			}),
		);

		const postTracks = async (
			sortedDonorTracks: IEditorTrack[],
			idx: number,
		): Promise<IEditorTrack[]> => {
			const tracks = await this.postTrack(sortedDonorTracks[idx]);

			return sortedDonorTracks[idx + 1]
				? postTracks(sortedDonorTracks, idx + 1)
				: tracks;
		};

		try {
			const [audioLayers, videoLayers] = await Promise.all(
				[sortedAudioTracks, sortedLayerTracks].map((tracks) =>
					tracks.length ? postTracks(tracks, 0) : [],
				),
			);

			await this._withPromiseWrapper<IVideoEditorStore['project']>(() => {
				if (!this.project) {
					throw new Error(
						`The property "this.project" is required: ${this.project}`,
					);
				}

				return this.initProject({
					project: {
						...this.project,
						audioLayers: audioLayers,
						videoLayers: videoLayers,
					},
				});
			})();
		} catch (e) {
			toast.error(handleError(e));
		}
	};

	@action compileProject: IVideoEditorStore['compileProject'] = async (
		withDownload = true,
	) => {
		if (!this.project) return null;
		const projectId = this.project.id;

		const compileProject: TPromiseExecutor<FileResponse> = async (
			resolve,
			reject,
		) => {
			try {
				const { data: editorData } =
					await swaggerApi.api.editorExportStatus(projectId);
				const exportData = editorData.data;

				if (
					this.renderingStatusByProjectId[projectId] !==
					exportData.renderingStatus
				) {
					this._compiledStatusByProjectId[projectId] = !exportData.renderedFile;

					runInAction(() => {
						this.renderingStatusByProjectId[projectId] =
							exportData.renderingStatus;
					});
				}

				toastDownload(
					projectId,
					exportData.renderingPercent,
					this.renderingStatusByProjectId[projectId],
				);

				switch (exportData.renderingStatus) {
					case DEFAULT_RENDERING_STATUS:
						{
							try {
								await swaggerApi.api.editorExport(projectId, {});

								setTimeout(
									() => compileProject(resolve, reject),
									ONE_SECOND_MS,
								);
							} catch (e) {
								reject(e);
							}
						}
						break;
					case RenderingStatus.Pending:
						{
							setTimeout(
								() => compileProject(resolve, reject),
								5 * ONE_SECOND_MS,
							);
						}
						break;
					case RenderingStatus.Ready:
						{
							if (exportData.renderedFile) {
								resolve(exportData.renderedFile);
							} else {
								reject(
									new Error(
										exportData.renderingError || 'Ошибка во время рендеринга',
									),
								);
							}
						}
						break;
					case RenderingStatus.Error:
						{
							reject(
								new Error(
									exportData.renderingError || 'Ошибка во время рендеринга',
								),
							);
						}
						break;
				}
			} catch (e) {
				reject(e);
			}
		};

		try {
			const file = await new Promise<FileResponse>(compileProject);

			if (withDownload) {
				explorerStore.download(file);
			}

			return file.id;
		} catch (e) {
			toast.error(handleError(e));
			return null;
		} finally {
			this._compiledStatusByProjectId[projectId] = false;
		}
	};

	async setDurationToSelectedTracks(duration: number) {
		if (!this.project) return;
		const { selectedTrackIds } = this;
		if (!selectedTrackIds.length) return;
		const noUpdatedTrackIds: string[] = [];

		const setter = (tracks: IEditorTrack[]) => {
			const sorted = tracks.sort((t1, t2) => (t1.start > t2.start ? 1 : -1));
			let totalDuration = 0;

			const updatedTracks = sorted.map((t) => {
				let result = t;

				if (
					result.type !== FileType.Video &&
					selectedTrackIds.includes(result.id)
				) {
					result = {
						...result,
						duration,
						cutFrom: result.cutFrom,
						cutTo: result.cutFrom + duration,
						start: totalDuration,
					};
				} else if (totalDuration !== result.start) {
					result.start = totalDuration;
				} else {
					noUpdatedTrackIds.push(result.id);
				}

				totalDuration += result.duration;

				return result;
			});

			return [updatedTracks, totalDuration] as [IEditorTrack[], number];
		};

		const [layerTracks, layerTotalDuration] = setter(this.layerTracks);
		const [audioTracks, audioTotalDuration] = setter(this.audioTracks);

		await Promise.all(
			[...layerTracks, ...audioTracks]
				.filter((t) => !noUpdatedTrackIds.includes(t.id))
				.map((t) =>
					this.updateTrack(t.id, t.type, {
						duration: t.duration,
						cutFrom: t.cutFrom,
						cutTo: t.cutTo,
						start: t.start,
					}),
				),
		);

		this.unSelectTracks();

		return this.initProject({
			project: {
				...this.project,
				totalDuration:
					layerTotalDuration > audioTotalDuration
						? layerTotalDuration
						: audioTotalDuration,
				videoLayers: layerTracks,
				audioLayers: audioTracks,
			},
		});
	}

	exportToDevices: IVideoEditorStore['exportToDevices'] = async (
		devices,
		bid,
	) => {
		if (!this.project) return;
		if (!devices.length) return;
		const projectId = this.project.id;

		// TODO@nikshirobokov: Consolidate localStorage queries.
		let editorLocalStorage = localStorage.getItem(EDITOR_STORAGE);
		if (editorLocalStorage) {
			const parsedEditorLocalStorage = JSON.parse(editorLocalStorage);
			parsedEditorLocalStorage.exportToDevices = {
				...(parsedEditorLocalStorage.exportToDevices || {}),
				[projectId]: { devices, bid },
			};
			localStorage.setItem(
				EDITOR_STORAGE,
				JSON.stringify(parsedEditorLocalStorage),
			);
		} else {
			localStorage.setItem(
				EDITOR_STORAGE,
				JSON.stringify({
					exportToDevices: {
						[projectId]: { devices, bid },
					},
				}),
			);
		}

		const mediaId = await this.compileProject(false);
		if (!mediaId) return;

		const playlistName = this.project.name || `editor-playlist-${projectId}`;
		let playlist =
			playlistsStore.list.find((p) => p.name === playlistName) || null;

		if (!playlist) {
			const playlists = await playlistsStore.findPlaylist({
				name: playlistName,
			});
			if (playlists.length) {
				playlist = playlists[0];
			}
		}

		if (!playlist) {
			playlist = await playlistsStore.createPlaylist({
				files: [mediaId],
				name: playlistName,
				description: appStore.intl.formatMessage(
					{
						id: 'Editor playlist description',
						defaultMessage:
							'Список воспроизведения создан автоматически, когда проект "{name}" отправляется на устройства.',
					},
					{ name: this.project.name },
				),
			});
		}
		if (!playlist) {
			toast.error(
				appStore.intl.formatMessage(
					{
						id: 'Editor playlist failed',
						defaultMessage: 'Не удалось создать плейлист из проекта "{name}".',
					},
					{ name: this.project.name },
				),
				{ autoClose: false },
			);
			return;
		}

		await monitorsStore.linkMonitorPlaylistMap(
			devices.map((d) => d.id),
			playlist.id,
			bid,
		);

		toast.success(
			appStore.intl.formatMessage(
				{
					id: 'Export to devices success',
					defaultMessage: 'Экспорт в устройства: {devices} успешно завершен.',
				},
				{ devices: `${devices.map((d) => d.name).join(', ')}` },
			),
			{ autoClose: false },
		);

		editorLocalStorage = localStorage.getItem(EDITOR_STORAGE);
		if (editorLocalStorage) {
			const parsedEditorLocalStorage = JSON.parse(editorLocalStorage);
			if (parsedEditorLocalStorage.exportToDevices) {
				parsedEditorLocalStorage.exportToDevices = Object.fromEntries(
					Object.entries(parsedEditorLocalStorage.exportToDevices).filter(
						([pId]) => pId !== projectId,
					),
				);
				localStorage.setItem(
					EDITOR_STORAGE,
					JSON.stringify(parsedEditorLocalStorage),
				);
			}
		}
	};

	@action
	initProject: IVideoEditorStore['initProject'] = async ({
		projectId,
		project,
	}) => {
		this.moveTimelinePointer({ x: 0 });

		if (projectId === DRAFT_PROJECT_ID) {
			projectId = undefined;
			project = cloneDeep(draftProject);
		}

		if (project) {
			this.project = normalizeProject(project);
		} else if (projectId) {
			const initProjectCallback = async () => {
				if (!projectId) return null;
				const { data: editorData } = await swaggerApi.api.editorGet(projectId);

				return editorData.data ? normalizeProject(editorData.data) : null;
			};

			try {
				this.project = await this._withPromiseWrapper(initProjectCallback)();
			} catch (e) {
				this.project = null;
				toast.error(handleError(e));
			}
		} else {
			this.project = null;
		}
		this._loadMediaLayers();
		this._initHistory();

		if (this.project) {
			this.renderingStatusByProjectId[this.project.id] =
				this.project.renderingStatus;

			const editorLocalStorage = localStorage.getItem(EDITOR_STORAGE);
			let exportData;

			if (editorLocalStorage) {
				const parsedEditorLocalStorage = JSON.parse(editorLocalStorage);
				exportData =
					parsedEditorLocalStorage.exportToDevices &&
					parsedEditorLocalStorage.exportToDevices[this.project.id];
			}

			if (
				(this.project.renderingStatus === 'pending' || exportData) &&
				!this._compiledStatusByProjectId[this.project.id]
			) {
				if (exportData) {
					this.exportToDevices(exportData.devices, exportData.bid);
				} else {
					this.compileProject();
				}
			} else if (this.project.renderingStatus !== 'initial') {
				toastDownload(
					this.project.id,
					this.project.renderingPercent,
					this.project.renderingStatus,
				);
			}
		}

		return this.project;
	};

	@action cancelProjectCreation = async () => {
		if (!this.project) {
			return;
		}

		await this.deleteProject(this.project.id);

		await this.initProject({
			projectId: DRAFT_PROJECT_ID,
			project: draftProject,
		});
	};

	@action.bound pause = () => {
		this.isPlayed = false;
		cancelAnimationFrame(this._timelineAnimationFrame);
		this._timelineAnimationFrame = 0;
		this._pauseAudio();
		this._pauseLayer();
	};

	@action.bound play = () => {
		if (this._timelineAnimationFrame) {
			this.pause();
		}
		const animate = this._animate();
		if (animate) {
			this.isPlayed = true;
			this._playAudio();
			this._playLayer();
			this._timelineAnimationFrame = requestAnimationFrame(animate);
		}
	};

	@action postTrack: IVideoEditorStore['postTrack'] = async (
		draftTrack,
		excludedKeys = ['id'],
	) => {
		if (!this.project) {
			throw new Error(`Project is required ${this.project}`);
		}
		const isAudio = draftTrack.type === FileType.Audio;

		excludedKeys.push('mediaId', 'type');

		switch (draftTrack.type) {
			case FileType.Audio:
				{
					draftTrack.mixVolume =
						typeof draftTrack.mixVolume === 'number'
							? draftTrack.mixVolume
							: DEFAULT_MEDIA_VOLUME;
				}
				break;
			case FileType.Video:
				{
					if (this.project.keepSourceAudio) {
						draftTrack.mixVolume =
							typeof draftTrack.mixVolume === 'number'
								? draftTrack.mixVolume
								: DEFAULT_MEDIA_VOLUME;
					} else {
						draftTrack.mixVolume = 0;
					}
				}
				break;
		}

		const postTrackCallback = async () => {
			if (!this.project) {
				throw new Error(`Project is required ${this.project}`);
			}

			await swaggerApi.api.editorLayerCreate(
				this.project.id,
				filterObject(
					{
						...draftTrack,
						file: draftTrack.mediaId,
					},
					{
						includedKeys: trackRequestKeys,
						excludedKeys,
					},
				),
			);

			await this.initProject({
				projectId: this.project.id,
			});

			return isAudio ? this.project.audioLayers : this.project.videoLayers;
		};

		try {
			return this._withPromiseWrapper<IEditorTrack[]>(postTrackCallback)();
		} catch (e) {
			toast.error(handleError(e));
			return isAudio ? this.project.audioLayers : this.project.videoLayers;
		}
	};

	@action updateTrack: IVideoEditorStore['updateTrack'] = async (
		trackId,
		trackType,
		draftTrack,
		withProjectInit,
	) => {
		if (!this.project) {
			throw new Error(`Project is required ${this.project}`);
		}
		const isAudio = trackType === FileType.Audio;
		const excludedKeys: Array<keyof IEditorTrack> = [
			'id',
			'type',
			'mediaId',
			'file',
		];
		const existingTracks = isAudio
			? this.project.audioLayers
			: this.project.videoLayers;

		const updateCallback = async () => {
			if (!this.project) {
				throw new Error(`Project is required ${this.project}`);
			}
			const { data: editorData } = await swaggerApi.api.editorLayerUpdate(
				this.project.id,
				trackId,
				filterObject(draftTrack, {
					includedKeys: trackRequestKeys,
					excludedKeys,
				}),
			);
			const tracks = existingTracks.map((t) =>
				t.id === trackId ? normalizeLayer(editorData.data) : t,
			);

			if (withProjectInit) {
				await this.initProject({
					project: {
						...this.project,
						...(isAudio ? { audioLayers: tracks } : { videoLayers: tracks }),
					},
				});
			}

			return tracks;
		};

		try {
			return this._withPromiseWrapper<IEditorTrack[]>(updateCallback)();
		} catch (e) {
			toast.error(handleError(e));
			return isAudio ? this.project.audioLayers : this.project.videoLayers;
		}
	};

	@action.bound toggleVolume: IVideoEditorStore['toggleVolume'] = (
		track,
		volume,
	) => {
		if (track.type === 'image') return;
		let mixVolume;
		if (typeof volume === 'number') {
			mixVolume = volume;
		} else {
			mixVolume = track.mixVolume === 0 ? 1 : 0;
		}
		this.updateTrack(
			track.id,
			track.type,
			{
				mixVolume,
			},
			true,
		);
	};

	@action.bound toggleView() {
		if (this.view === 'window') {
			this.view = 'fullscreen';
		} else if (this.view === 'fullscreen') {
			this.view = 'window';
		}
	}

	@action.bound zoomIn = () => {
		const secondsToPixelFactor = this.secondsToPixelFactor / ZOOM_FACTOR_PX;
		const minScaleSeconds = this.scaleUnitSeconds / ZOOM_FACTOR_SEC;
		if (minScaleSeconds >= MIN_SCALE_UNIT_SECONDS) {
			this.secondsToPixelFactor = secondsToPixelFactor;
			this.scaleUnitSeconds = minScaleSeconds;
		}
	};

	@action.bound zoomOut = () => {
		const secondsToPixelFactor = this.secondsToPixelFactor * ZOOM_FACTOR_PX;
		const minScaleSeconds = this.scaleUnitSeconds * ZOOM_FACTOR_SEC;
		if (MAX_SCALE_UNIT_SECONDS >= minScaleSeconds) {
			this.secondsToPixelFactor = secondsToPixelFactor;
			this.scaleUnitSeconds = minScaleSeconds;
		}
	};

	@action.bound _animate = () => {
		if (!this.project) return null;
		const fpsInterval = ONE_SECOND_MS / this.project.fps;
		let startTime = window.performance.now() - this._currentFrame;
		let then = startTime;

		const animate = (frame: number) => {
			if (!this.project) {
				return;
			}

			this._timelineAnimationFrame = requestAnimationFrame(animate);

			if (this.waitAudioId || this.waitLayerId) {
				startTime = window.performance.now() - this._currentFrame;
				return;
			}

			const elapsed = frame - then;

			if (elapsed > fpsInterval) {
				then = frame - (elapsed % fpsInterval);
				this._drawPreview();
				this._soundPreview();
				this._currentFrame = frame - startTime;

				const end = this.currentSeconds > this.project.totalDuration;

				if (end) {
					this.pause();
					this.moveTimelinePointer({ x: 0 });
				}
			}
		};
		return animate;
	};

	_drawPreview() {
		if (!this.project) return false;
		const { current: canvas } = this.previewCanvasRef;
		if (!canvas) return false;
		const ctx = canvas.getContext('2d');

		if (this.previewLayer) {
			let mediaLayer;
			if (this.previewLayer.type === FileType.Video) {
				mediaLayer = this.mediaIdToVideo[this.previewLayer.mediaId];

				if (this.isPlayed) {
					if (
						mediaLayer.paused ||
						this.playedLayerId !== this.previewLayer.id
					) {
						this._playLayer();
					}
				} else {
					mediaLayer.currentTime =
						(this.previewLayer.cutFrom || 0) +
						this.currentSeconds -
						this.previewLayer.start;
				}
				if (mediaLayer.volume !== this.previewLayer.mixVolume) {
					mediaLayer.volume = this.previewLayer.mixVolume;
				}
			} else {
				if (this.playedLayerId) {
					const track = this.project.videoLayers.find(
						(l) => l.id === this.playedLayerId,
					);
					if (track) {
						const video = this.mediaIdToVideo[track.mediaId];
						video.pause();
					}
				}
				mediaLayer = this.mediaIdToImage[this.previewLayer.mediaId];
			}
			ctx?.drawImage(mediaLayer, 0, 0, this.project.width, this.project.height);
		} else {
			Object.values(this.mediaIdToVideo).forEach((v) => v.pause());
			ctx?.clearRect(0, 0, this.project.width, this.project.height);
		}

		return true;
	}

	_soundPreview() {
		if (this.previewAudio) {
			const audio = this.mediaIdToAudio[this.previewAudio.mediaId];
			if (this.isPlayed) {
				if (audio.paused || this.playedAudioId !== this.previewAudio.id) {
					this._playAudio();
				}
			} else {
				audio.currentTime =
					(this.previewAudio.cutFrom || 0) +
					this.currentSeconds -
					this.previewAudio.start;
			}
			if (audio.volume !== this.previewAudio.mixVolume) {
				audio.volume = this.previewAudio.mixVolume;
			}
		} else {
			Object.values(this.mediaIdToAudio).forEach((audio) => audio.pause());
		}

		return true;
	}

	_loadMediaLayers() {
		if (!this.project) return;

		const notLoadedAudioTracks = this.project.audioLayers
			.map((l) => {
				if (l.mediaId in this.mediaIdToAudio) return;
				const audio = document.createElement('AUDIO') as HTMLAudioElement;
				audio.onwaiting = () => {
					this.waitAudioId = l.id;
				};
				audio.onplaying = () => {
					this.waitAudioId = null;
				};
				this.mediaIdToAudio[l.mediaId] = audio;

				return l;
			})
			.filter((l): l is IEditorTrack => Boolean(l));

		const notLoadedLayerTracks = this.project.videoLayers
			.map((l) => {
				switch (l.type) {
					case FileType.Image: {
						if (l.mediaId in this.mediaIdToImage) return;
						this.mediaIdToImage[l.mediaId] = document.createElement(
							'IMG',
						) as HTMLImageElement;

						return l;
					}
					case FileType.Video: {
						if (l.mediaId in this.mediaIdToVideo) return;
						const video = document.createElement('VIDEO') as HTMLVideoElement;
						video.onwaiting = () => {
							this.waitLayerId = l.id;
						};
						video.onplaying = () => {
							this.waitLayerId = null;
						};
						this.mediaIdToVideo[l.mediaId] = video;

						return l;
					}
					default:
						return;
				}
			})
			.filter((l): l is IEditorTrack => Boolean(l));

		Promise.all(
			[notLoadedAudioTracks, notLoadedLayerTracks].flat().map(async (l) => {
				const blob = await explorerStore.getFileS3(l.mediaId, l.file.name);
				if (!blob) return;

				let mediaLayer;

				switch (l.type) {
					case FileType.Audio:
						{
							mediaLayer = this.mediaIdToAudio[l.mediaId];
						}
						break;
					case FileType.Image:
						{
							mediaLayer = this.mediaIdToImage[l.mediaId];
						}
						break;
					case FileType.Video: {
						mediaLayer = this.mediaIdToVideo[l.mediaId];
					}
				}

				if (mediaLayer) {
					mediaLayer.src = window.URL.createObjectURL(blob);
				}
			}),
		);
	}

	_pauseAudio() {
		if (!this.previewAudio) return;
		const audio = this.mediaIdToAudio[this.previewAudio.mediaId];
		audio.pause();
		this.playedAudioId = null;
	}

	_pauseLayer() {
		if (this.previewLayer && this.previewLayer.type === FileType.Video) {
			const video = this.mediaIdToVideo[this.previewLayer.mediaId];
			video.pause();
			this.playedLayerId = null;
		}
	}

	async _playAudio() {
		if (!this.previewAudio) return;
		const audio = this.mediaIdToAudio[this.previewAudio.mediaId];
		audio.volume = this.previewAudio.mixVolume;
		audio.currentTime =
			(this.previewAudio.cutFrom || 0) +
			this.currentSeconds -
			this.previewAudio.start;

		if (audio.paused) {
			await audio.play();
		}

		this.playedAudioId = this.previewAudio.id;
	}

	async _playLayer() {
		if (this.previewLayer && this.previewLayer.type === FileType.Video) {
			const video = this.mediaIdToVideo[this.previewLayer.mediaId];
			video.volume = this.previewLayer.mixVolume;
			video.currentTime =
				(this.previewLayer.cutFrom || 0) +
				this.currentSeconds -
				this.previewLayer.start;

			if (video.paused) {
				await video.play();
			}

			this.playedLayerId = this.previewLayer.id;
		}
	}

	@action _initHistory() {
		if (!this.project) return this.history;
		const editorLocalStorage = localStorage.getItem(EDITOR_STORAGE);
		let projectHistory;

		if (editorLocalStorage) {
			const parsedEditorLocalStorage = JSON.parse(editorLocalStorage);
			if (parsedEditorLocalStorage) {
				projectHistory =
					parsedEditorLocalStorage.historyByProjectId &&
					parsedEditorLocalStorage.historyByProjectId[this.project.id];
			}
		}

		this.history = projectHistory || {
			lastItemId: '',
			items: [],
		};

		return this.history;
	}

	@action _updateHistory: IVideoEditorStore['_updateHistory'] = (
		action,
		body,
	) => {
		if (
			!this.project ||
			(this._contextHistoryItem && this._contextHistoryItem.action !== action)
		) {
			return;
		}

		if (this._contextHistoryItem) {
			const { id: historyItemId } = this._contextHistoryItem;
			this.history = {
				...this.history,
				items: this.history.items.map((item) =>
					historyItemId === item.id
						? {
								...item,
								action,
								body,
							}
						: item,
				),
			};
		} else {
			const lastIdx = this.history.items.findIndex(
				(item) => item.id === this.history.lastItemId,
			);
			const createdAt = Date.now();
			const historyItem: IHistoryItem = {
				action,
				createdAt,
				body,
				id: createdAt.toString(),
			};

			this.history = {
				lastItemId: historyItem.id,
				items: this.history.items
					.sort((item1, item2) => (item1.createdAt > item2.createdAt ? 1 : -1))
					.filter((item, idx) => lastIdx >= idx)
					.concat(historyItem),
			};
		}

		const editorLocalStorage = localStorage.getItem(EDITOR_STORAGE);
		let draftEditorLocalStorage;
		if (editorLocalStorage) {
			const parsedEditorLocalStorage = JSON.parse(editorLocalStorage);
			draftEditorLocalStorage = {
				...parsedEditorLocalStorage,
				historyByProjectId: {
					...(parsedEditorLocalStorage.historyByProjectId || {}),
					[this.project.id]: this.history,
				},
			};
		} else {
			draftEditorLocalStorage = {
				historyByProjectId: {
					[this.project.id]: this.history,
				},
			};
		}

		localStorage.setItem(
			EDITOR_STORAGE,
			JSON.stringify(draftEditorLocalStorage),
		);
	};

	_withPromiseWrapper: IVideoEditorStore['_withPromiseWrapper'] = (promise) => {
		return async (...args) => {
			try {
				appStore.isLoading = true;
				const wasPlayed = this.isPlayed;
				if (wasPlayed) {
					this.pause();
				}

				const promiseResult = await promise(...args);

				if (wasPlayed && !this.isPlayed) {
					this.play();
				}

				return promiseResult;
			} finally {
				appStore.isLoading = false;
			}
		};
	};
}

export const videoEditorStore = new VideoEditorStore();
