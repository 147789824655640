import deepEqual from 'deep-equal';

export type TReducerFunction<T> = (
	unique: T[],
	item: T,
	idx: number,
	arr: T[],
) => T[];

export type TCheckInclusionFunction<T> = (
	unique: T[],
	item: T,
	idx?: number,
	arr?: T[],
) => boolean;

export interface IUniqueArrayOptions<T> {
	key?: keyof T;
	needDeepEqual?: boolean;
}

export function uniqueArray<T>(array: T[], options?: IUniqueArrayOptions<T>) {
	const { key } = options || {};
	const needDeepEqual = options?.needDeepEqual || Boolean(key);

	let checkInclusion: TCheckInclusionFunction<T> = (unique, item) =>
		unique.includes(item);

	if (needDeepEqual) {
		checkInclusion = (unique, item) => {
			return unique.some((uniqItem) =>
				deepEqual(uniqItem, item, { strict: true }),
			);
		};
	}

	if (key) {
		checkInclusion = (unique, item) => {
			const keys = unique.map((uniqueItem) => uniqueItem[key]);
			return keys.includes(item[key]);
		};
	}

	return array.reduce((unique, item) => {
		if (checkInclusion(unique, item)) {
			return unique;
		}

		return [...unique, item];
	}, [] as T[]);
}
