import { FC, HTMLAttributes } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input } from '../../../components/forms';
import { useForm } from '../../../hooks';
import cx from 'classnames';
import {
	DEFAULT_IMAGE_DURATION_SECONDS,
	videoEditorStore,
} from '../../../modules/video-editor-module';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '../../../components/icon';
import { icons } from '../../../assets/icons';

export interface ITimelineDurationFormProps
	extends HTMLAttributes<HTMLDivElement> {
	selectedCount: number;
}

export const TimelineDurationForm: FC<ITimelineDurationFormProps> = observer(
	({ selectedCount, className }) => {
		const intl = useIntl();
		const [{ formProps, values, canSubmit }, { submit }] = useForm(
			{
				duration: 0,
			},
			{
				required: ['duration'],
				onSubmit: ({ duration }) => {
					void videoEditorStore.setDurationToSelectedTracks(duration);
				},
			},
		);

		return (
			<div className={cx('timeline-duration-form', className)}>
				<span className="timeline-duration-form__count">
					<FormattedMessage
						id="TimelineDurationCount"
						defaultMessage="Задать время слоям ({count}):"
						values={{ count: selectedCount }}
					/>
				</span>
				<Form {...formProps}>
					<Input
						name="duration"
						value={values.duration}
						fieldHint={intl
							.formatMessage({
								id: 'app.units.seconds',
								defaultMessage: 'сек',
							})
							.toLowerCase()}
						rawPlaceholder={String(DEFAULT_IMAGE_DURATION_SECONDS)}
					/>
				</Form>
				{canSubmit && (
					<Icon
						className={cx('timeline-duration-form__button', icons.Save)}
						size={18}
						onClick={submit}
						title={intl.formatMessage({
							id: 'Apply',
							defaultMessage: 'Применить',
						})}
					/>
				)}
			</div>
		);
	},
);
