import {
	action,
	computed,
	makeObservable,
	observable,
	reaction,
	runInAction,
	when,
} from 'mobx';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { downloadFile, handleError, swaggerApi, toast } from 'utils';
import {
	ContentType,
	FileResponse,
	FilesUploadResponse,
	FolderResponse,
	InvoiceGetResponse,
	InvoiceResponse,
	MonitorGetResponse,
	MonitorResponse,
	Order,
	RequestParams,
} from 'utils/api/api';
import { appStore } from 'stores/app';
import { IExplorerStore } from '_types/stores';
import { authStore } from '../auth';
import { taskStore } from 'modules/task-manager';
import { MonitorFileCategory } from '../monitors/monitor-list';

export interface IMediaIds {
	fileIds: string[];
	folderIds: string[];
}

export interface IClipboard {
	fileIds: string[];
	folderIds: string[];
	mode: 'copy' | 'cut';
}

export type TUploadFilesContext =
	| { folderId?: string | null }
	| { invoiceId: InvoiceResponse['id'] }
	| {
			monitorId: MonitorResponse['id'];
			category: MonitorFileCategory;
	  };

class ExplorerStore implements IExplorerStore {
	@observable renameId: string | null = null;
	@observable fileList: FileResponse[] = [];

	@observable selected: Record<string, string[]> = {
		fileIds: [],
		folderIds: [],
	};

	@observable foldersList: FolderResponse[] = [];

	@observable breadcrumbs: null | FolderResponse[] = null;

	@observable clipboard: null | IClipboard = null;
	@observable folderCreation: {
		isActive: boolean;
		value?: string;
	} = {
		isActive: false,
	};

	constructor() {
		makeObservable(this);

		reaction(() => this.currentFolderId, this.getMediaList);
		when(
			() => !authStore.wasAuthenticated,
			() => this.clear(),
		);
	}

	@computed get currentFolderId() {
		return this.breadcrumbs && this.breadcrumbs[this.breadcrumbs.length - 1].id;
	}

	@action clear = () => {
		this.clearClipboard();
		this.clearSelected();
		this.breadcrumbs = null;
		this.foldersList = [];
		this.fileList = [];
		this.folderCreation = {
			isActive: false,
		};
	};

	@action clearSelected = () => {
		if (this.selected.fileIds.length || this.selected.folderIds.length) {
			this.selected = {
				fileIds: [],
				folderIds: [],
			};
		}
	};

	@action clearClipboard = () => {
		this.clipboard = null;
	};

	@action getMediaList = () => {
		const source = axios.CancelToken.source();

		Promise.all([
			this.currentFolderId &&
				swaggerApi.api
					.filesGet({
						where: { folderId: this.currentFolderId },
						scope: {
							order: {
								createdAt: Order.DESC,
							},
						},
					})
					.then((fileResponse) =>
						runInAction(() => {
							this.fileList = fileResponse.data.data;
						}),
					),
			this.currentFolderId
				? swaggerApi.api
						.foldersGet({
							where: { parentFolderId: this.currentFolderId },
							scope: {
								order: {
									createdAt: Order.ASC,
								},
							},
						})
						.then((folderResponse) =>
							runInAction(() => {
								this.foldersList = folderResponse.data.data;
							}),
						)
				: swaggerApi.api
						.foldersGet({
							where: { parentFolderId: null },
							scope: {
								order: {
									createdAt: Order.ASC,
								},
							},
						})
						.then((folderResponse) =>
							this.setupNavigation(folderResponse.data.data[0]),
						),
		]).catch((error) => {
			toast.error(handleError(error));
		});

		return source;
	};

	@action
	async uploadFiles(
		files: File[],
		context?: TUploadFilesContext,
		axiosProps?: AxiosRequestConfig,
	) {
		const { onUploadProgress } = axiosProps || {};
		const multipleFiles = files.length > 1;
		const fileNames = files.map((f) => f.name).join(', ');
		const task = taskStore.createTask({
			showProgress: true,
			message: appStore.intl.formatMessage(
				{
					id: multipleFiles ? 'Upload files' : 'Upload file',
					defaultMessage: 'Загружаем "{name}"',
				},
				{ name: fileNames },
			),
		});
		const params: RequestParams = {
			headers: {
				accept: 'application/json',
				'Content-Type': ContentType.FormData,
			},
			timeout: 0,
			...(axiosProps || {}),
			onUploadProgress(...args) {
				const [{ loaded, total }] = args;
				if (total) {
					task.update({
						percent: Math.floor((loaded * 100) / total),
					});
				}
				if (onUploadProgress) {
					onUploadProgress(...args);
				}
			},
		};
		let method: (
			params: RequestParams | undefined,
		) => Promise<
			AxiosResponse<
				FilesUploadResponse | InvoiceGetResponse | MonitorGetResponse
			>
		> = swaggerApi.api.fileUpload.bind(this, {
			files,
			folderId: this.currentFolderId,
		});

		if (context) {
			switch (true) {
				case 'folderId' in context:
					{
						method = swaggerApi.api.fileUpload.bind(this, {
							files,
							folderId: context.folderId,
						});
					}
					break;
				case 'invoiceId' in context:
					{
						method = swaggerApi.api.invoiceUpload.bind(
							this,
							context.invoiceId,
							{
								file: files[0],
							},
						);
					}
					break;

				case 'monitorId' in context:
					{
						switch (context.category) {
							case 'Photo':
								{
									method = swaggerApi.api.monitorUploadPhotos.bind(
										this,
										context.monitorId,
										{
											files,
										},
									);
								}
								break;
							case MonitorFileCategory.Documents:
								{
									method = swaggerApi.api.monitorUploadDocuments.bind(
										this,
										context.monitorId,
										{
											files,
										},
									);
								}
								break;
						}
					}
					break;
			}
		}

		try {
			const { data: uploadData } = await method(params);

			if (Array.isArray(uploadData.data)) {
				this.fileList = [...this.fileList, ...uploadData.data];
			}

			task.success(
				appStore.intl.formatMessage(
					{
						id: multipleFiles ? 'Uploads Success' : 'Upload Success',
						defaultMessage: multipleFiles
							? 'Загружены файлы {name}'
							: 'Загружен файл {name}',
					},
					{ name: fileNames },
				),
			);

			return uploadData;
		} catch {
			task.fail(
				appStore.intl.formatMessage(
					{
						id: 'Upload Error',
						defaultMessage: 'Ошибка загрузки "{name}"',
					},
					{ name: fileNames },
				),
			);
		} finally {
			appStore.isLoading = false;
		}
	}

	@action updateMedia = async (id: string, newName: string): Promise<void> => {
		try {
			await swaggerApi.api.fileUpdate(id, {
				name: newName,
			});
			void this.getMediaList();
		} catch (error) {
			toast.error(handleError(error));
		}
	};

	@action deleteFile: IExplorerStore['deleteFile'] = async (id) => {
		appStore.isLoading = true;

		try {
			await swaggerApi.api.fileDelete(id);
			this.getMediaList();
		} catch (error) {
			toast.error(handleError(error));
		} finally {
			appStore.isLoading = false;
		}
	};

	download: IExplorerStore['download'] = async (item, fileName) => {
		fileName = fileName || item.name;
		const blob = await this.getFileS3(item.id, fileName, true);

		if (blob) {
			downloadFile(window.URL.createObjectURL(blob), fileName);
		}
	};

	getFileS3: IExplorerStore['getFileS3'] = async (
		fileId,
		fileName,
		showProgress,
	) => {
		const task = taskStore.createTask({
			id: fileId,
			showProgress,
			message: appStore.intl.formatMessage(
				{
					id: 'Download file',
					defaultMessage: 'Скачиваем "{name}"',
				},
				{ name: fileName },
			),
		});

		try {
			const res = await swaggerApi.api.fileDownload(fileId, {
				timeout: 0,
				onDownloadProgress: ({ total, loaded }) => {
					if (total) {
						task.update({ percent: Math.floor((loaded * 100) / total) });
					}
				},
			});
			task.success(
				appStore.intl.formatMessage(
					{
						id: 'Download Success',
						defaultMessage: 'Скачан файл "{name}"',
					},
					{
						name: fileName,
					},
				),
			);
			return res.data as unknown as Blob;
		} catch (e) {
			task.fail(
				appStore.intl.formatMessage(
					{
						id: 'Download Error',
						defaultMessage: 'Ошибка при скачивании файла "{name}"',
					},
					{ name: fileName },
				),
			);
			toast.error(handleError(e));
			return null;
		}
	};

	getFilePreview: IExplorerStore['getFilePreview'] = async (fileId) => {
		try {
			const res = await swaggerApi.api.fileDownloadPreview(fileId);
			return res.data as unknown as Blob;
		} catch {
			return null;
		}
	};

	@action createFolder = async (
		name = appStore.intl.formatMessage({ id: 'New folder' }),
	): Promise<void> => {
		appStore.isLoading = true;

		try {
			await swaggerApi.api.folderCreate({
				name,
				...(this.currentFolderId
					? { parentFolderId: this.currentFolderId }
					: {}),
			});

			this.getMediaList();
		} catch (error) {
			toast.error(handleError(error));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action turnFolderCreationOn = () => {
		this.folderCreation = {
			isActive: true,
			value: appStore.intl.formatMessage({ id: 'New folder' }),
		};
	};

	@action turnFolderCreationOff = () => {
		if (this.folderCreation.value) {
			this.folderCreation.isActive = false;
			this.createFolder(this.folderCreation.value);
		}
	};

	@action updateFolder = async (id: string, newName: string): Promise<void> => {
		try {
			await swaggerApi.api.folderUpdate(id, {
				name: newName,
			});

			void this.getMediaList();
		} catch (error) {
			toast.error(handleError(error));
		}
	};

	@action select = (
		type?: FileResponse['type'] | 'file' | 'folder',
		ids?: FolderResponse['id'] | FolderResponse['id'][],
	): (FileResponse | FolderResponse)[] => {
		if (!type) {
			return (['file', 'folder'] as const).flatMap((type) => this.select(type));
		}
		const key = type === 'folder' ? 'folderIds' : 'fileIds';
		const list =
			type === 'folder'
				? this.foldersList
				: type === 'file'
					? this.fileList
					: this.fileList.filter((f) => f.type === type);

		if (ids) {
			ids = Array.isArray(ids) ? ids : [ids];
			return ids
				.map((id) => {
					if (this.selected[key].includes(id)) {
						runInAction(() => {
							this.selected[key] = this.selected[key].filter(
								(selectedId) => selectedId !== id,
							);
						});
						return null;
					}
					runInAction(() => {
						this.selected[key] = this.selected[key].concat(id);
					});
					const file = list.find((f) => f.id === id);
					if (!file) {
						throw new Error(`The file with ID "${id}" was not found`);
					}
					return file;
				})
				.filter((f): f is NonNullable<typeof f> => Boolean(f));
		} else {
			if (this.selected[key].length) {
				this.selected[key] = [];
				return [];
			} else {
				this.selected[key] = list.map((f) => f.id);
				return list;
			}
		}
	};

	@action deleteFolder = async (id: string): Promise<void> => {
		appStore.isLoading = true;

		try {
			await swaggerApi.api.folderDelete(id);
			this.getMediaList();
		} catch (error) {
			toast.error(handleError(error));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action deleteSelected = async (): Promise<void> => {
		appStore.isLoading = true;

		try {
			const requests = [];

			if (this.selected.folderIds.length) {
				requests.push(
					swaggerApi.api.foldersDelete({
						foldersId: this.selected.folderIds,
					}),
				);
			}

			if (this.selected.fileIds.length) {
				requests.push(
					swaggerApi.api.filesDelete({
						filesId: this.selected.fileIds,
					}),
				);
			}

			await Promise.all(requests);

			this.getMediaList();

			this.clearSelected();
		} catch (error) {
			toast.error(handleError(error));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action setupNavigation = (rootFolder: FolderResponse) => {
		if (this.breadcrumbs?.length && this.breadcrumbs[0].id === rootFolder.id) {
			return;
		}

		this.breadcrumbs = [rootFolder];
	};

	@action navigateForward = (folder: FolderResponse) => {
		if (!this.breadcrumbs) {
			return;
		}

		if (folder.id === this.currentFolderId) {
			return;
		}

		this.breadcrumbs = [...this.breadcrumbs, folder];
	};

	@action rename = (id?: typeof this.renameId) => {
		if (id !== undefined) {
			this.renameId = id;
		} else {
			this.renameId = this.selected.folderIds[0] || this.selected.fileIds[0];
		}

		this.clearSelected();
	};

	@action navigateBackward = () => {
		if (!this.breadcrumbs) {
			return;
		}

		if (this.breadcrumbs.length === 1) {
			return;
		}

		this.breadcrumbs = this.breadcrumbs.slice(0, -1);
	};

	@action navigateBackwardTo = (n: number) => {
		if (!this.breadcrumbs) {
			return;
		}

		this.breadcrumbs = this.breadcrumbs.slice(0, n);
	};

	@action copyToClipboard = () => {
		if (!this.selected.fileIds.length && !this.selected.folderIds.length) {
			return;
		}

		this.clipboard = {
			fileIds: this.selected.fileIds,
			folderIds: this.selected.folderIds,
			mode: 'copy',
		};

		this.clearSelected();

		toast.success('Copied');
	};

	@action cutToClipboard = () => {
		if (!this.selected.fileIds.length && !this.selected.folderIds.length) {
			return;
		}

		this.clipboard = {
			fileIds: this.selected.fileIds,
			folderIds: this.selected.folderIds,
			mode: 'cut',
		};

		this.clearSelected();
	};

	@action pasteFromClipboard = async (
		targetFolderId?: string,
	): Promise<void> => {
		const { clipboard, currentFolderId, breadcrumbs } = this;

		targetFolderId = targetFolderId || currentFolderId || undefined;

		if (!clipboard || !targetFolderId || !breadcrumbs) {
			return;
		}

		appStore.isLoading = true;

		if (clipboard.mode === 'copy') {
			try {
				// eslint-disable-next-line  @typescript-eslint/no-explicit-any
				const requests: Promise<any>[] = [];

				if (clipboard.folderIds.length) {
					requests.push(
						swaggerApi.api.foldersCopy({
							folders: clipboard.folderIds.map((folderId) => {
								return {
									id: folderId,
								};
							}),
							toFolder: targetFolderId,
						}),
					);
				}

				if (clipboard.fileIds.length) {
					requests.push(
						swaggerApi.api.filesCopy({
							files: clipboard.fileIds.map((fileId) => {
								return {
									id: fileId,
								};
							}),
							toFolder: targetFolderId,
						}),
					);
				}

				await Promise.all(requests);

				this.getMediaList();

				this.clearClipboard();
			} catch (error) {
				toast.error(handleError(error));
			} finally {
				appStore.isLoading = false;
			}
		}

		if (clipboard.mode === 'cut') {
			try {
				// eslint-disable-next-line  @typescript-eslint/no-explicit-any
				const requests: any[] = [];

				if (clipboard.folderIds.length) {
					const isNested = breadcrumbs
						.map((breadcrumb) => breadcrumb.id)
						.some((breadcrumb) => clipboard.folderIds.includes(breadcrumb));

					if (isNested) {
						toast.error('Cannot be inserted into a cut folder');
						return;
					}

					requests.push(
						swaggerApi.api.foldersUpdate({
							folders: clipboard.folderIds.map((folderId) => {
								return {
									id: folderId,
									parentFolderId: targetFolderId,
								};
							}),
						}),
					);
				}

				if (clipboard.fileIds.length) {
					requests.push(
						swaggerApi.api.filesUpdate({
							files: clipboard.fileIds.map((fileId) => {
								return {
									id: fileId,
									folderId: targetFolderId,
								};
							}),
						}),
					);
				}

				await Promise.all(requests);

				this.getMediaList();

				this.clearClipboard();
			} catch (error) {
				toast.error(handleError(error));
			} finally {
				appStore.isLoading = false;
			}
		}
	};

	@action moveItemToFolder(itemId: string, folderId: string) {
		const item =
			this.fileList.find((f) => f.id === itemId) ||
			this.foldersList.find((f) => f.id === itemId);

		if (!item) return;
		const isFile = 'type' in item;

		if (isFile) {
			if (item.folderId === folderId) return;
		} else {
			if (item.parentFolderId === folderId) return;
		}
		const folder =
			this.foldersList.find((f) => f.id === folderId) ||
			this.breadcrumbs?.find((f) => f.id === folderId);

		if (!folder || folder.id === item.id) return;
		const selected = this.selected[isFile ? 'fileIds' : 'folderIds'].includes(
			item.id,
		);
		if (!selected) {
			explorerStore.select(isFile ? 'file' : 'folder', item.id);
		}
		explorerStore.cutToClipboard();
		explorerStore.pasteFromClipboard(folder.id);
	}
}

export const explorerStore = new ExplorerStore();
