import { FC, HTMLAttributes, MouseEventHandler, useCallback } from 'react';
import { FileResponse, FileType } from 'utils/api/api';
import { defaultPreview, useFilePreview } from 'hooks/use-file-preview';

export interface IFilePreviewProps extends HTMLAttributes<HTMLElement> {
	file: FileResponse;
}

export const FilePreview: FC<IFilePreviewProps> = ({ file, className }) => {
	const previewLink = useFilePreview(file);

	const stopPlay = useCallback<MouseEventHandler<HTMLVideoElement>>(
		(e) => {
			if (previewLink) {
				if (e.currentTarget.paused) {
					e.currentTarget.play().catch(console.error);
				} else {
					e.currentTarget.pause();
				}
			}
		},
		[previewLink],
	);

	return file.type === FileType.Video && previewLink ? (
		<video
			className={className}
			src={previewLink}
			muted={true}
			loop={true}
			onMouseLeave={stopPlay}
			onMouseEnter={stopPlay}
			onError={() => null}
		/>
	) : (
		<img
			src={previewLink || defaultPreview(file.type)}
			className={className}
			alt={file.name}
			onError={() => null}
		/>
	);
};
