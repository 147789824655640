import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

export interface IConfirmationProps {
	messageId: string;
	onConfirm?: () => void;
	onCancel?: () => void;
}

export const Confirmation: FC<IConfirmationProps> = ({
	messageId,
	onConfirm,
	onCancel,
}) => {
	return (
		<div className="confirmation__layout">
			<div className="confirmation__window">
				<div>
					<p className="confirmation__text">
						<FormattedMessage id={messageId} defaultMessage={messageId} />
					</p>
					<div className="confirmation__buttons">
						<button
							className="confirmation__button confirmation__button--confirm"
							onClick={onConfirm}
						>
							<FormattedMessage id="Confirm" defaultMessage="Confirm" />
						</button>
						<button
							className="confirmation__button confirmation__button--cancel"
							onClick={onCancel}
						>
							<FormattedMessage id="Cancel" defaultMessage="Cancel" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
