import { FC, HTMLAttributes, useEffect, useMemo, useRef } from 'react';
import { videoEditorStore } from '../store';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import './style.scss';
import { appStore } from 'stores/app';

const EditorPreviewComponent: FC<HTMLAttributes<HTMLDivElement>> = ({
	className,
	...restProps
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const canvasSizes = useMemo(
		() => ({
			width: videoEditorStore.project?.width,
			height: videoEditorStore.project?.height,
		}),
		[videoEditorStore.project?.width, videoEditorStore.project?.height],
	);

	useEffect(() => {
		const { current: canvas } = videoEditorStore.previewCanvasRef;
		const { current: container } = containerRef;
		if (canvas && container && canvasSizes.width && canvasSizes.height) {
			const { clientHeight, clientWidth } = container;
			const ratio = canvasSizes.width / canvasSizes.height;
			const width = clientHeight * ratio;
			if (width > clientWidth) {
				canvas.style.width = `${clientWidth}px`;
				canvas.style.height = `${clientWidth / ratio}px`;
			} else {
				canvas.style.width = `${width > clientWidth ? clientWidth : width}px`;
				canvas.style.removeProperty('height');
			}
		}
	}, [
		appStore.layout.windowWidth,
		appStore.layout.windowHeight,
		videoEditorStore.view,
		canvasSizes,
	]);

	return (
		<div
			{...restProps}
			className={cx('preview-canvas', className)}
			ref={containerRef}
		>
			<canvas
				ref={videoEditorStore.previewCanvasRef}
				className="preview-canvas__canvas"
				{...canvasSizes}
			/>
		</div>
	);
};

export const EditorPreview = observer(EditorPreviewComponent);
