import {
	FC,
	HTMLAttributes,
	RefObject,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { explorerStore } from 'stores/media';

import { FileResponse, FileType } from 'utils/api/api';

import {
	DRAFT_PROJECT_ID,
	videoEditorStore,
} from 'modules/video-editor-module';
import { ChangeNameForm } from '../change-name-form';
import { uniqueArray, upFirstLetter } from 'utils/formatters';
import { Explorer, IExplorerItemProps } from 'components/explorer';
import { EditorDurationForm } from '../editor-duration-form';
import { Icon } from '../../../../components/icon';
import { icons } from '../../../../assets/icons';
import deepEqual from 'deep-equal';

export interface IEditorLibrary extends HTMLAttributes<HTMLDivElement> {
	timelineRef: RefObject<HTMLDivElement>;
}

const MEDIA_TYPES = [FileType.Video, FileType.Image, FileType.Audio];

export const EditorLibrary: FC<IEditorLibrary> = observer(
	({ timelineRef, className, ...restProps }) => {
		const [currentType, setCurrentType] = useState(FileType.Video);
		const [selectedFiles, setSelectedFiles] = useState<FileResponse[]>([]);

		const selectAll = useCallback(() => {
			const files = uniqueArray(
				selectedFiles.concat(
					explorerStore.select(currentType) as FileResponse[],
				),
				{ key: 'id' },
			);

			if (deepEqual(selectedFiles, files, { strict: true })) {
				setSelectedFiles([]);
			} else {
				setSelectedFiles(files);
			}
		}, [selectedFiles, explorerStore.select, currentType]);

		const [changeNameFormIsOpen, setChangeNameFormIsOpen] = useState(false);

		const handleChangeNameFormClose = useCallback(() => {
			setChangeNameFormIsOpen(false);
		}, [setChangeNameFormIsOpen]);

		const handleChangeNameFormSave = useCallback(
			(name: string) => {
				videoEditorStore.addMedia(name, selectedFiles);
				explorerStore.clearSelected();
				setSelectedFiles([]);

				setChangeNameFormIsOpen(false);
			},
			[selectedFiles],
		);

		const addMediaToTimeline = useCallback(() => {
			if (!videoEditorStore.project) return;
			if (!selectedFiles.length) return;

			if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
				setChangeNameFormIsOpen(true);
				return;
			}

			videoEditorStore.addMedia(null, selectedFiles);
			explorerStore.clearSelected();
			setSelectedFiles([]);
		}, [selectedFiles, videoEditorStore.project?.id]);

		const mediaList = useMemo(
			() => explorerStore.fileList.filter((f) => f.type === currentType),
			[currentType, explorerStore.fileList],
		);

		const selectedImagesCount = useMemo(
			() =>
				selectedFiles.filter((f) => f.type === FileType.Image).length || null,
			[selectedFiles],
		);

		const handleItemSelect = useCallback<
			NonNullable<IExplorerItemProps['onItemSelect']>
		>(
			(item, isSelect) => {
				if ('type' in item) {
					setSelectedFiles((state) => {
						if (isSelect) {
							return uniqueArray([...state, item], { key: 'id' });
						}
						return state.filter((f) => f.id !== item.id);
					});
				}
			},
			[setSelectedFiles],
		);

		useEffect(() => {
			if (selectedFiles.length) {
				videoEditorStore.unSelectTracks();
			}
		}, [selectedFiles]);

		useEffect(() => {
			if (videoEditorStore.selectedTracks.length) {
				explorerStore.clearSelected();
				setSelectedFiles([]);
			}
		}, [videoEditorStore.selectedTracks]);

		return (
			<div
				{...restProps}
				className={cx('editor__content editor__content__layout', className)}
			>
				{videoEditorStore.project && (
					<ChangeNameForm
						open={changeNameFormIsOpen}
						status="create"
						value={videoEditorStore.project.name}
						canBeClosed={false}
						onClose={handleChangeNameFormClose}
						onSave={handleChangeNameFormSave}
					/>
				)}
				<div className="editor__content__bar">
					{MEDIA_TYPES.map((type) => (
						<button
							key={type}
							className={cx('editor__content__item', {
								'editor__content__item--active': currentType === type,
							})}
							onClick={() => setCurrentType(type)}
						>
							<FormattedMessage
								id={upFirstLetter(type)}
								defaultMessage={upFirstLetter(type)}
							/>
						</button>
					))}
				</div>
				<Explorer
					files={mediaList}
					customContextMenu={false}
					noActions="folder"
					onItemSelect={handleItemSelect}
				/>
				<div className="video-library__actions">
					{selectedFiles.length ? (
						<div className="video-library__actions-form">
							<span className="video-library__actions-title">
								<FormattedMessage
									id="Selected"
									defaultMessage="Выбрано файлов ({count})"
									values={{ count: selectedFiles.length }}
								/>
							</span>
							{selectedImagesCount && (
								<EditorDurationForm selectedCount={selectedImagesCount} />
							)}
						</div>
					) : null}
					<button
						disabled={videoEditorStore.isReadOnly}
						className="video-library__action"
						onClick={selectAll}
					>
						<Icon className={icons.SelectWhite} />
						<FormattedMessage id="Select All" defaultMessage="Select All" />
					</button>
					<button
						disabled={videoEditorStore.isReadOnly}
						className="video-library__action"
						onClick={addMediaToTimeline}
					>
						<Icon className={icons.Upload} />
						<FormattedMessage
							id="On the timeline"
							defaultMessage="On the timeline"
						/>
					</button>
				</div>
			</div>
		);
	},
);
