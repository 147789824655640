import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { IToastTaskOptions, toastTask } from '../../utils/toast/toast-task';
import { ReactNode } from 'react';

export enum TaskStatus {
	Failed = 'Failed',
	Success = 'Success',
	Initial = 'Initial',
	Progress = 'Progress',
	Paused = 'Paused',
}

export interface ITaskOptions
	extends Partial<
		Pick<
			Task,
			'id' | 'message' | 'percent' | 'toastOptions' | 'render' | 'showProgress'
		>
	> {}

export class Task {
	@observable id = '';
	@observable percent = 0;
	@observable message: ReactNode = '';
	@observable toastOptions: Partial<IToastTaskOptions> = {};
	@observable showProgress = false;

	constructor(options?: Partial<ITaskOptions>, manualStart?: boolean) {
		makeObservable(this);

		if (options) {
			this.update(options);
		}

		autorun(() => {
			const used = [
				this.percent,
				this.message,
				this.id,
				this.showProgress,
				this.toastOptions,
				this.status,
			];

			this.render();
		});

		if (!manualStart) {
			this.start();
		}
	}

	@observable _status: TaskStatus = TaskStatus.Initial;

	@computed get status() {
		return this._status;
	}

	render = () => {
		if (this.showProgress || this.status === TaskStatus.Failed) {
			return this._renderToast();
		}
	};

	@action.bound start() {
		this._status = TaskStatus.Progress;
	}

	@action update(state: ITaskOptions) {
		const { id, message, percent, toastOptions, render, showProgress } = state;

		if (render) {
			this.render = render;
		}

		if (id) {
			this.id = id;
		}
		if (percent) {
			this.percent = percent;
		}
		if (message && message !== this.message) {
			this.message = message;
		}
		if (toastOptions) {
			this.toastOptions = {
				...this.toastOptions,
				...toastOptions,
			};
		}
		if (showProgress !== undefined) {
			this.showProgress = showProgress;
		}
	}

	@action.bound pause() {
		this._status = TaskStatus.Paused;
	}

	@action success(message: Task['message'], options?: ITaskOptions) {
		this.message = message;
		this._status = TaskStatus.Success;
		if (options) {
			this.update(options);
		}
	}

	@action fail(message: Task['message'], options?: ITaskOptions) {
		this.message = message;
		this._status = TaskStatus.Failed;
		if (options) {
			this.update(options);
		}
	}

	@action _renderToast() {
		const options: Partial<IToastTaskOptions> = this.toastOptions;

		if (this.percent !== options.percent) {
			options.percent = this.percent;
		}
		switch (this.status) {
			case TaskStatus.Failed:
				{
					options.failed = true;
				}
				break;

			case TaskStatus.Success: {
				options.done = true;
				options.percent = undefined;
			}
		}

		const toastId = toastTask(this.message, options);

		if (!this.id) {
			this.id = toastId;
		}

		if (!this.toastOptions.toastId) {
			this.toastOptions.toastId = toastId;
		}
	}
}
