import {
	ComponentType,
	DragEvent,
	FC,
	HTMLAttributes,
	useCallback,
	useEffect,
	useMemo,
} from 'react';

import './explorer.scss';
import { explorerStore } from '../../stores/media';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { ExplorerItem, IExplorerItemProps } from './explorer-item';
import { FileResponse, FileType, FolderResponse } from '../../utils/api/api';
import { Dnd, handleDndDrop, IDndProps } from '../dnd';

export interface IExplorerProps
	extends Pick<IExplorerItemProps, 'noActions' | 'onItemSelect' | 'onNavigate'>,
		HTMLAttributes<HTMLDivElement> {
	acceptableFileTypes?: FileType[];
	files?: FileResponse[];
	ItemComponent?: ComponentType<IExplorerItemProps>;
	ItemActionComponent?: ComponentType<Pick<IExplorerItemProps, 'item'>>;
	customContextMenu?: boolean;
}

export const Explorer: FC<IExplorerProps> = observer(
	({
		noActions,
		onItemSelect,
		customContextMenu = true,
		className,
		files,
		acceptableFileTypes,
		ItemComponent = ExplorerItem,
		onNavigate,
	}) => {
		const navigate = useCallback(
			(idx?: number) => {
				if (idx) {
					explorerStore.navigateBackwardTo(idx);
				} else {
					explorerStore.navigateBackward();
				}
				if (onNavigate) {
					onNavigate();
				}
			},
			[
				explorerStore.navigateBackwardTo,
				explorerStore.navigateBackward,
				onNavigate,
			],
		);

		const fileList = useMemo(() => {
			if (files) {
				return files;
			}
			return acceptableFileTypes
				? explorerStore.fileList.filter((f) =>
						acceptableFileTypes.includes(f.type),
					)
				: explorerStore.fileList;
		}, [files, acceptableFileTypes, explorerStore.fileList]);

		useEffect(() => {
			const source = explorerStore.getMediaList();

			return () => {
				source.cancel();
				explorerStore.clearSelected();
				explorerStore.clearClipboard();
			};
		}, []);

		const handleDrop = useCallback<NonNullable<IDndProps['onDrop']>>(
			({ droppedKey, draggedKey }) => {
				explorerStore.moveItemToFolder(draggedKey, droppedKey);
			},
			[explorerStore.moveItemToFolder],
		);

		return (
			<div className={cx('explorer', className)}>
				<div className="explorer__folder-path-layout">
					<button
						className="explorer__path-circle"
						onClick={() => navigate()}
						type="button"
					/>
					<div className="explorer__folder-path">
						{explorerStore.breadcrumbs?.map((breadcrumb, i, arr) => {
							const handleClick = () => {
								if (i + 1 === arr.length) {
									return;
								}

								navigate(i + 1);
							};

							const handleDrop = (e: DragEvent) => {
								const draggedKey = handleDndDrop(e);
								if (draggedKey) {
									e.stopPropagation();
									explorerStore.moveItemToFolder(draggedKey, breadcrumb.id);
								}
							};

							return (
								<button
									key={breadcrumb.id}
									className="explorer__folder-path-item"
									onClick={handleClick}
									type="button"
									onDrop={handleDrop}
								>
									{breadcrumb.parentFolderId ? (
										<>&nbsp;/&nbsp;{breadcrumb.name}</>
									) : (
										<FormattedMessage id="Library" defaultMessage="Library" />
									)}
								</button>
							);
						})}
					</div>
				</div>
				<Dnd className="grid explorer__dnd" onDrop={handleDrop}>
					{[...explorerStore.foldersList, ...fileList]
						.filter((f): f is FileResponse | FolderResponse => Boolean(f))
						.map((item) => (
							<ItemComponent
								key={item.id}
								item={item}
								noActions={noActions}
								customContextMenu={customContextMenu}
								onItemSelect={onItemSelect}
								onNavigate={onNavigate}
							/>
						))}
				</Dnd>
			</div>
		);
	},
);
