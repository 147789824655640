import { FC, HTMLAttributes } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input } from '../../../components/forms';
import { useForm } from '../../../hooks';
import {
	DEFAULT_IMAGE_DURATION_SECONDS,
	videoEditorStore,
} from '../../../modules/video-editor-module';
import { FormattedMessage, useIntl } from 'react-intl';
import { runInAction } from 'mobx';
import { errorStore } from '../../../stores/error-store';

export interface IEditorLibraryDurationFormProps
	extends HTMLAttributes<HTMLDivElement> {
	selectedCount: number;
}

export const EditorDurationForm: FC<IEditorLibraryDurationFormProps> = observer(
	({ selectedCount }) => {
		const intl = useIntl();
		const [{ formProps, values }] = useForm(
			{
				duration: videoEditorStore.imageDurationSeconds,
			},
			{
				parsers: {
					duration: (value) => ({
						duration: Number(value),
					}),
				},
				onChange: ({ duration }) => {
					if (duration > 0) {
						runInAction(() => {
							videoEditorStore.imageDurationSeconds = duration;
						});
					} else {
						errorStore.setError('duration');
						return {
							duration: videoEditorStore.imageDurationSeconds,
						};
					}
				},
				onBlur: ({ duration }, names) => {
					if (names.includes('duration')) {
						if (duration < 0) {
							errorStore.setError('duration');
							return {
								duration: videoEditorStore.imageDurationSeconds,
							};
						} else {
							errorStore.takeOff('duration');
						}
					}
				},
			},
		);

		return (
			<Form className="video-library-duration-form" {...formProps}>
				<span className="video-library-duration-form__count">
					<FormattedMessage
						id="TimelineDurationCount"
						defaultMessage="Задать время изображениям ({count}):"
						values={{ count: selectedCount }}
					/>
				</span>
				<Input
					name="duration"
					value={values.duration}
					fieldHint={intl
						.formatMessage({
							id: 'app.units.seconds',
							defaultMessage: 'сек',
						})
						.toLowerCase()}
					rawPlaceholder={String(DEFAULT_IMAGE_DURATION_SECONDS)}
				/>
			</Form>
		);
	},
);
