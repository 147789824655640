import React, {
	ChangeEvent,
	ComponentType,
	FC,
	HTMLAttributes,
	MouseEvent,
	ReactNode,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import OutsideClickHandler from 'react-outside-click-handler';
import TextareaAutosize from 'react-textarea-autosize';

import { Confirmation, Layout } from 'components/common';

import { formatBytes, formatSeconds } from 'utils';
import { FileResponse, FileType, FolderResponse } from 'utils/api/api';
import { explorerStore } from 'stores/media';
import { FilePreview } from './file-preview';
import { FileStatus } from './file-status';
import './explorer.scss';
import { Icon } from '../icon';
import { icons } from '../../assets/icons';
import { PopupFullscreen } from '../popup';
import { CLASSNAMES } from '../dnd';

export interface IExplorerItemProps extends HTMLAttributes<HTMLDivElement> {
	ActionComponent?: ComponentType<{ item: IExplorerItemProps['item'] }>;
	actionElement?: ReactNode;
	customContextMenu?: boolean;
	noActions?: boolean | 'folder' | 'file';
	item: FileResponse | FolderResponse;
	onItemSelect?: (item: IExplorerItemProps['item'], isSelect: boolean) => void;
	onNavigate?: () => void;
}

export const ExplorerItem: FC<IExplorerItemProps> = observer(
	({
		children,
		item,
		noActions: noActionsProperty,
		customContextMenu = false,
		onItemSelect,
		onNavigate,
	}) => {
		const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
		const isFile = useMemo(() => 'type' in item, [item]);
		const noActions = useMemo(() => {
			if (typeof noActionsProperty === 'string') {
				switch (noActionsProperty) {
					case 'file':
						return isFile;
					case 'folder':
						return !isFile;
				}
			}
			return noActionsProperty;
		}, [isFile, noActionsProperty]);

		const [state, setState] = useState({
			menuIsActive: false,
			renaming: {
				isActive: false,
				value: item.name,
			},
		});

		const handleNameBlur = useCallback(() => {
			if (state.renaming.value !== item.name) {
				if (isFile) {
					void explorerStore.updateMedia(item.id, state.renaming.value);
				} else {
					void explorerStore.updateFolder(item.id, state.renaming.value);
				}
			}
			explorerStore.rename(null);
		}, [state.renaming.value, item.name, item.id, isFile]);

		const turnMenuOn = (event: MouseEvent<HTMLDivElement>) => {
			if (!customContextMenu) {
				return;
			}

			event.preventDefault();
			setState({
				...state,
				menuIsActive: true,
			});
		};

		const turnMenuOff = () => {
			setState({
				...state,
				menuIsActive: false,
			});
		};

		const turnRenamingOn = () => {
			setState({
				...state,
				menuIsActive: false,
				renaming: {
					...state.renaming,
					isActive: true,
				},
			});
		};

		const turnRenamingOff = () => {
			setState({
				...state,
				renaming: {
					...state.renaming,
					isActive: false,
				},
			});
		};

		const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
			setState({
				...state,
				renaming: {
					...state.renaming,
					value: event.target.value,
				},
			});
		};

		const handleNameKeydown = (
			event: React.KeyboardEvent<HTMLTextAreaElement>,
		) => {
			if (event.code === 'Enter') {
				event.preventDefault();

				setState({
					...state,
					renaming: {
						...state.renaming,
						isActive: false,
					},
				});
				handleNameBlur();
			}
		};

		const handleDelete = useCallback(() => {
			setConfirmationIsOpen(true);
		}, []);

		const renderMenu = () => {
			if (state.menuIsActive) {
				return (
					<OutsideClickHandler onOutsideClick={turnMenuOff}>
						<div
							className="explorer__item-menu"
							onClick={(e) => e.stopPropagation()}
						>
							<div className="explorer__item-action" onClick={turnRenamingOn}>
								<FormattedMessage id="Rename" defaultMessage="Rename" />
							</div>
							<div className="explorer__item-action" onClick={handleDelete}>
								<FormattedMessage id="Delete" defaultMessage="Delete" />
							</div>
						</div>
					</OutsideClickHandler>
				);
			}

			return null;
		};

		const renderItemName = () => {
			if (explorerStore.renameId === item.id || state.renaming.isActive) {
				return (
					<OutsideClickHandler onOutsideClick={turnRenamingOff}>
						<TextareaAutosize
							autoFocus
							className="explorer__item-textarea"
							value={state.renaming.value}
							onChange={handleNameChange}
							onKeyDown={handleNameKeydown}
							onBlur={handleNameBlur}
							maxRows={2}
						/>
					</OutsideClickHandler>
				);
			}

			return (
				<div className="explorer__item-filename" title={item.name}>
					{item.name}
				</div>
			);
		};

		const handleConfirm = useCallback(() => {
			if (isFile) {
				void explorerStore.deleteFolder(item.id);
			} else {
				void explorerStore.deleteFile(item.id);
			}

			setConfirmationIsOpen(false);
		}, [isFile, setConfirmationIsOpen]);

		const handleDownloadClick = useCallback(
			() => isFile && explorerStore.download(item as FileResponse),
			[isFile, item],
		);

		const select = useCallback(() => {
			const files = explorerStore.select(isFile ? 'file' : 'folder', item.id);
			if (onItemSelect) {
				const isSelect = files.some((f) => f.id === item.id);

				onItemSelect(item, isSelect);
			}
		}, [isFile, item, onItemSelect]);

		const handleCheckboxClick = useCallback(
			(e: MouseEvent<HTMLDivElement>) => {
				e.stopPropagation();
				select();
			},
			[select],
		);

		const handleClick = useCallback(
			(e: MouseEvent<HTMLDivElement>) => {
				if (isFile) {
					if (e.currentTarget.closest(CLASSNAMES.GRABBING)) return;
					select();
				} else {
					explorerStore.navigateForward(item as FolderResponse);
					if (onNavigate) {
						onNavigate();
					}
				}
			},
			[isFile, item, onNavigate],
		);

		return (
			<>
				{confirmationIsOpen && (
					<PopupFullscreen onOutsideClick={() => setConfirmationIsOpen(false)}>
						<Confirmation
							messageId={
								'empty' in item && !item.empty
									? 'Among the deleted folders there are folders with nested elements that will be lost when deleted. Continue deleting?'
									: 'Are you sure to delete the selected one?'
							}
							onConfirm={handleConfirm}
							onCancel={() => {
								setConfirmationIsOpen(false);
							}}
						/>
					</PopupFullscreen>
				)}
				<Layout
					flex
					column
					className="explorer__item"
					onContextMenu={turnMenuOn}
					onClick={handleClick}
				>
					<Layout flex column className="explorer__item-preview">
						{isFile ? (
							<>
								<FilePreview
									file={item as FileResponse}
									className="explorer__item-preview_image"
								/>
								<span className="explorer__item-preview_ext">
									{(item as FileResponse).extension}
								</span>
							</>
						) : (
							<Icon className={icons.LibraryFolder} size={75} />
						)}
					</Layout>
					{renderItemName()}
					{isFile && (
						<>
							<FileStatus
								className="explorer__item-status"
								file={item as FileResponse}
							/>
							<div className="explorer__item-file-info">
								{(item as FileResponse).type !== FileType.Image && (
									<span className="explorer__item-file-info_duration">
										{(item as FileResponse).duration
											? formatSeconds((item as FileResponse).duration)
											: null}
									</span>
								)}
								<span className="explorer__item-file-info_size">
									{(item as FileResponse).filesize
										? formatBytes((item as FileResponse).filesize)
										: null}
								</span>
							</div>
						</>
					)}
					{!noActions && (
						<div className="explorer__item-actions">
							{isFile && (
								<span
									className="explorer__item-download"
									onClick={handleDownloadClick}
								>
									<FormattedMessage id="Download" defaultMessage="Download" />
								</span>
							)}
							<input
								className="explorer__item-checkbox"
								type="checkbox"
								onChange={() => null}
								onClick={handleCheckboxClick}
								checked={
									isFile
										? explorerStore.selected.fileIds.includes(item.id)
										: explorerStore.selected.folderIds.includes(item.id)
								}
							/>
						</div>
					)}
					{children}
					{renderMenu()}
				</Layout>
			</>
		);
	},
);
